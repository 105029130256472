import React, { FC, useCallback, useState, useEffect, FormEvent } from 'react';
import gql from 'graphql-tag';
import { Typography, TextField, Button } from '@material-ui/core';
import styles from './index.module.css';
import { useMutation } from '@apollo/react-hooks';
import { login, loginVariables } from '__generated__/login';

const LOGIN_MUTATION = gql`
  mutation login($loginInput: LoginInput!) {
    login(input: $loginInput) {
      token
      user {
        email
        id
        name
        isAdmin
      }
    }
  }
`;

const Login: FC = () => {
  const [login, loginResult] = useMutation<login, loginVariables>(
    LOGIN_MUTATION
  );

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = (e: FormEvent) => {
    e.preventDefault();
    login({
      variables: {
        loginInput: {
          email,
          password
        }
      }
    });
  };

  useEffect(() => {
    if (!loginResult.called || loginResult.loading) {
      return;
    }
    if (!loginResult.error && loginResult.data) {
      window.localStorage.setItem('token', loginResult.data.login.token);
      window.location.href = '/';
    }
  }, [loginResult]);

  return (
    <div className={styles.container}>
      <Typography variant="h4">Login</Typography>
      <form onSubmit={handleLogin}>
        <TextField
          label="Email"
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <TextField
          label="Password"
          value={password}
          onChange={e => setPassword(e.target.value)}
          type="password"
        />
        <div className={styles.loginButton}>
          {loginResult.error && 'Failed to login. Please try again.'}
          <Button fullWidth={true} variant="contained" type="submit">
            Login
          </Button>
        </div>
      </form>
    </div>
  );
};

export { Login };
