import { InputFormat } from 'types/input-format';
import { IFormField, IDataModel } from 'types/form-types';

const isBooleanInput = <DataModel = IDataModel>(
  field: IFormField<DataModel>
) => {
  return field.format === InputFormat.Boolean;
};

export { isBooleanInput };
