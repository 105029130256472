import { unitPriceFragment } from '__generated__/unitPriceFragment';

export interface IBucketDefinition {
  dimension1: number;
  dimension2: number;
}

const standardBuckets: IBucketDefinition[] = [
  {
    dimension1: 5,
    dimension2: 5,
  },
  {
    dimension1: 5,
    dimension2: 10,
  },
  {
    dimension1: 10,
    dimension2: 10,
  },
  {
    dimension1: 10,
    dimension2: 15,
  },
  {
    dimension1: 10,
    dimension2: 20,
  },
  {
    dimension1: 10,
    dimension2: 25,
  },
  {
    dimension1: 10,
    dimension2: 30,
  },
];

interface IBucket extends IBucketDefinition {
  unitPrices: unitPriceFragment[];
  annualPricePerSqFt: number;
  averagePrice: number;
}

const tolerance = 0.1;

export type BucketUnitPricesResult = {
  buckets: IBucket[];
  unmatchedUnitPrices: unitPriceFragment[];
};

const bucketUnitPrices = (
  unitPrices: unitPriceFragment[],
  bucketDefinitions = standardBuckets
): BucketUnitPricesResult => {
  const pricesToBucket = [...unitPrices];

  const buckets: IBucket[] = bucketDefinitions.map(
    (definiton) =>
      ({
        ...definiton,
        unitPrices: [],
      } as IBucket)
  );

  const unmatchedUnitPrices: unitPriceFragment[] = [];

  while (pricesToBucket.length) {
    const priceToBucket = pricesToBucket.shift();
    const priceSqFt = priceToBucket.dimension1 * priceToBucket.dimension2;
    const matchingBucket = buckets.find((bucket) => {
      const bucketSqFt = bucket.dimension1 * bucket.dimension2;
      const maxDiff = bucketSqFt * tolerance;

      return (
        priceSqFt >= bucketSqFt - maxDiff && priceSqFt <= bucketSqFt + maxDiff
      );
    });

    if (matchingBucket) {
      matchingBucket.unitPrices.push(priceToBucket);
    } else {
      unmatchedUnitPrices.push(priceToBucket);
    }
  }

  for (const bucket of buckets) {
    const pricePerSqFt = calculatePricePerSqFtOfBucket(bucket);
    bucket.annualPricePerSqFt = pricePerSqFt * 12;
    bucket.averagePrice =
      pricePerSqFt * (bucket.dimension1 * bucket.dimension2);
  }

  return { buckets, unmatchedUnitPrices };
};

const calculatePricePerSqFtOfBucket = (bucket: IBucket) => {
  let totalSqFt = 0;
  let totalPrice = 0;
  for (const unitPrice of bucket.unitPrices) {
    totalSqFt += unitPrice.dimension1 * unitPrice.dimension2;
    totalPrice += unitPrice.price;
  }

  return totalPrice / totalSqFt;
};

export { bucketUnitPrices };
