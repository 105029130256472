import React, { FC, useState } from 'react';
import { FormSection } from 'components/form-section';
import { CompetitorFields } from 'form-definitions/competitor-fields';
import { UnitPrices } from './unit-prices';
import { ICompetitorWrapper } from '.';
import { competitorFragment } from '__generated__/competitorFragment';
import { Table, Collapse, Typography, TableBody } from '@material-ui/core';
import ArrowRight from '@material-ui/icons/ArrowRight';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
interface ICompetitorFormProps {
  competitor: ICompetitorWrapper;
  onCompetitorChange: (fieldName: keyof competitorFragment, value: any) => void;
}

const CompetitorForm: FC<ICompetitorFormProps> = ({
  competitor,
  onCompetitorChange
}) => {
  const [detailsExpanded, setDetailsExpanded] = useState(true);
  return (
    <div>
      <div
        style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
        onClick={() => setDetailsExpanded(!detailsExpanded)}
      >
        {detailsExpanded ? <ArrowDropDown /> : <ArrowRight />}
        <Typography variant="h6">Competitor Details</Typography>
      </div>

      <Collapse in={detailsExpanded}>
        <Table size="small" style={{ width: 'auto' }}>
          <TableBody>
            <FormSection
              fieldDefinitions={CompetitorFields}
              formValues={competitor.formValues}
              onFieldChange={onCompetitorChange as any}
            />
          </TableBody>
        </Table>
      </Collapse>

      <UnitPrices
        unitPrices={competitor.unitPrices}
        setUnitPrices={newPrices => onCompetitorChange('unitPrices', newPrices)}
      />
    </div>
  );
};

export { CompetitorForm };
