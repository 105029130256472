import React, { FC, useState, useEffect } from 'react';
import TableCell, { TableCellProps } from '@material-ui/core/TableCell';
import { PlainTableCell } from 'components/plain-table-cell';

type FocusableTableCellProps = {
  children:
    | (({ focused }: { focused: boolean }) => JSX.Element)
    | (JSX.Element | JSX.Element[]);
  plain?: boolean;
} & TableCellProps;
const FocusableTableCell: FC<FocusableTableCellProps> = ({
  children,
  plain,
  ...tableCellProps
}) => {
  const [focused, setFocused] = useState(false);
  const tableCellRef = React.createRef<HTMLElement>();
  const TableCellComponent = plain ? PlainTableCell : TableCell;

  useEffect(() => {
    function removeFocusIfClickOutside(e: MouseEvent) {
      if (
        tableCellRef.current &&
        !tableCellRef.current.contains(e.target as Node)
      ) {
        setFocused(false);
      }
    }
    if (focused) {
      window.addEventListener('click', removeFocusIfClickOutside);
      return () => {
        window.removeEventListener('click', removeFocusIfClickOutside);
      };
    }
  }, [focused, tableCellRef]);

  return (
    <TableCellComponent
      onClick={() => setFocused(true)}
      {...tableCellProps}
      ref={tableCellRef}
    >
      {typeof children === 'function' ? children({ focused }) : children}
    </TableCellComponent>
  );
};

export { FocusableTableCell };
