import React, { FC, useState, useCallback, useEffect, useContext } from 'react';
import {
  Button,
  AppBar,
  Tabs,
  Tab,
  Toolbar,
  Typography,
  IconButton,
  makeStyles,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import moment from 'moment';

import ArrowBack from '@material-ui/icons/ArrowBack';

import { useInterval } from 'react-use';
import { useHistory } from 'react-router';
import { VersionSelect } from 'components/version-select';
import { getProjectMasterRecord_projectMasterRecord_versions } from '__generated__/getProjectMasterRecord';
import { projectFragment } from '__generated__/projectFragment';
import { VersionContext } from 'containers/project-view';

interface IProjectBarProps {
  onSave: () => void;
  saving: boolean;
  lastSuccessfulSave?: moment.Moment;
  project: projectFragment;
  selectedTab: ProjectTab;
  onTabChange: (newTab: ProjectTab) => void;
  dirty: boolean;
  error: string;
  versions: getProjectMasterRecord_projectMasterRecord_versions[];
  onVersionChange: (versionId: number) => void;
}

export enum ProjectTab {
  Controls = 'controls',
  Competitors = 'competitors',
  AggComp = 'aggcomp',
}

const TabNames = {
  [ProjectTab.Controls]: 'Controls',
  [ProjectTab.Competitors]: 'Competitors',
  [ProjectTab.AggComp]: 'Agg Comp',
};

const tabs = [ProjectTab.Controls, ProjectTab.AggComp, ProjectTab.Competitors];

const useStyles = makeStyles({
  title: {
    flexGrow: 1,
  },
});

const ProjectBar: FC<IProjectBarProps> = ({
  onSave,
  saving,
  lastSuccessfulSave,
  project,
  selectedTab,
  onTabChange,
  dirty,
  error,
  versions,
  onVersionChange,
}) => {
  const classes = useStyles({});
  const history = useHistory();
  const handleTabChange = useCallback(
    (event: React.ChangeEvent<{}>, newTab: ProjectTab) => {
      history.replace(newTab);
      onTabChange(newTab);
    },
    [onTabChange, history]
  );

  const [savedTimeAgo, setSavedTimeAgo] = useState<string>();

  const { viewingCurrentVersion } = useContext(VersionContext);

  const [tryingToRestore, setTryingToRestore] = useState(false);
  const requestRestore = useCallback(() => {
    setTryingToRestore(true);
  }, [setTryingToRestore]);
  const cancelRestore = useCallback(() => {
    setTryingToRestore(false);
  }, [setTryingToRestore]);

  useEffect(() => {
    if (lastSuccessfulSave) {
      setSavedTimeAgo(lastSuccessfulSave.fromNow());
    } else {
      setSavedTimeAgo(null);
    }
  }, [lastSuccessfulSave]);

  useInterval(
    () => {
      setSavedTimeAgo(lastSuccessfulSave.fromNow());
    },
    lastSuccessfulSave ? 5000 : null
  );

  return (
    <>
      <Dialog open={tryingToRestore}>
        <DialogTitle>Restore this version?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Restoring this version will make a copy of this version and set it
            as the lastest verion. Would you like to restore this version?
          </DialogContentText>
          <DialogActions>
            <Button color="primary" onClick={cancelRestore}>
              Cancel
            </Button>
            <Button color="secondary" onClick={onSave} autoFocus>
              Restore
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <AppBar position="sticky">
        <Toolbar variant="dense">
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => history.push('/')}
          >
            <ArrowBack />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {project.name}
          </Typography>

          {saving && <CircularProgress color="inherit" />}
          {!viewingCurrentVersion &&
            !saving &&
            !savedTimeAgo &&
            'You are viewing an old version'}
          {dirty && 'You have unsaved changes'}
          {!dirty &&
            !saving &&
            savedTimeAgo &&
            !error &&
            `Saved ${savedTimeAgo}`}
          {!saving && error && `${error}`}
          {viewingCurrentVersion ? (
            <Button onClick={onSave} color="inherit" disabled={saving}>
              Save
            </Button>
          ) : (
            <Button onClick={requestRestore} color="inherit" disabled={saving}>
              Restore
            </Button>
          )}
        </Toolbar>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Tabs value={selectedTab} onChange={handleTabChange}>
            {tabs.map((tab) => {
              return <Tab label={TabNames[tab]} key={tab} value={tab} />;
            })}
          </Tabs>
          <VersionSelect
            currentVersionId={project.id}
            versions={versions}
            onVersionChange={onVersionChange}
          />
        </div>
      </AppBar>
    </>
  );
};

export { ProjectBar };
