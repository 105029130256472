import { FormDefinition, HeaderType, InputWidth } from 'types/form-types';
import { InputFormat } from 'types/input-format';
import { projectFragment } from '__generated__/projectFragment';

const ProjectFields: FormDefinition<projectFragment> = [
  {
    name: 'name',
    label: 'Name',
    format: InputFormat.String,
    inputWidth: InputWidth.LongText,
    colSpan: 3,
  },
  {
    name: 'address',
    label: 'Address',
    format: InputFormat.String,
    inputWidth: InputWidth.LongText,
    colSpan: 3,
  },
  {
    name: 'listingWebsite',
    label: 'Listing Website/Source',
    format: InputFormat.String,
    inputWidth: InputWidth.LongText,
    colSpan: 3,
  },
];

export { ProjectFields };
