import React, { FC, useCallback, useMemo, useState, useEffect } from 'react';
import numeral from 'numeral';
import { formatNumber } from 'utils/format-number';
import { IFormField, IFormValues, IDataModel } from 'types/form-types';
import { InputCell } from 'components/input-cell';
import get from 'lodash/get';
import styles from './index.module.css';
import { Typography, Select, MenuItem, IconButton } from '@material-ui/core';
import { OpenInNew } from '@material-ui/icons';
import { isCalculatedField } from 'utils/is-calculated-field';
import { isInputField } from 'utils/is-input-field';
import { isSelectField } from 'utils/is-select-field';
import { HighlightColors } from 'types/colors';
import getUrls from 'get-urls';
import { InputFormat } from 'types/input-format';

interface IFormFieldProps {
  formField: IFormField;
  parsedFormValues: IDataModel;
  formValues: IFormValues;
  onFieldChange?: (fieldName: string, value: string) => void;
  color: string;
}
const FormField: FC<IFormFieldProps> = ({
  formField,
  parsedFormValues,
  formValues,
  onFieldChange,
  color,
}) => {
  const { name, format } = formField;

  const calculate = isCalculatedField(formField) ? formField.calculate : false;

  const recalculate = isInputField(formField) ? formField.recalculate : null;

  const value = useMemo(() => {
    return calculate ? calculate(parsedFormValues) : get(formValues, name);
  }, [calculate, parsedFormValues, formValues, name]);

  const [containedUrl, setContainedUrl] = useState();

  useEffect(() => {
    if (
      value &&
      isInputField(formField) &&
      formField.format === InputFormat.String
    ) {
      const urls = getUrls(value as string, {
        requireSchemeOrWww: false,
      });
      if (urls.size) {
        setContainedUrl(urls.values().next().value);
      }
    }
  }, [value, formField]);

  const onChange = useCallback(
    (newValue: string) => {
      onFieldChange(name as string, newValue);
      const parsedNewValue = numeral(newValue).value();
      parsedFormValues[name] = parsedNewValue;
      if (recalculate) {
        for (const { key, calculate } of recalculate) {
          const recalculatedValue = calculate(parsedFormValues, parsedNewValue);

          parsedFormValues[key] = recalculatedValue;
          onFieldChange(key as string, `${recalculatedValue}`);
        }
      }
    },
    [onFieldChange, name, parsedFormValues, recalculate]
  );

  if (calculate) {
    return (
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Typography
          style={{
            backgroundColor: color && HighlightColors[color].base,
            padding: '4px',
            marginRight: '8px',
          }}
          className={styles.computed}
        >
          {formatNumber(`${value}`, format, false)}
        </Typography>
      </div>
    );
  } else if (isSelectField(formField)) {
    return (
      <Select
        value={value || ''}
        onChange={(e) => onChange(e.target.value as string)}
        fullWidth
      >
        {formField.options.map((option) => {
          return (
            <MenuItem key={option.value} value={option.value}>
              {option.name}
            </MenuItem>
          );
        })}
      </Select>
    );
  } else {
    return (
      <div style={{ display: 'flex' }}>
        {containedUrl && (
          <IconButton onClick={() => window.open(containedUrl, 'blank')}>
            <OpenInNew />
          </IconButton>
        )}
        <InputCell
          formField={formField}
          color={color}
          onChange={onChange}
          value={value || ''}
        />
      </div>
    );
  }
};

export { FormField };
