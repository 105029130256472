import React, { FC } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from '@material-ui/core';

interface IConfirmationDialogProps {
  open: boolean;
  onClose: (confirmed: boolean) => void;
  thingToDelete: string;
  deleteNow?: boolean;
}

const ConfirmationDialog: FC<IConfirmationDialogProps> = ({
  open,
  onClose,
  thingToDelete,
  deleteNow = false
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle id="alert-dialog-title">
        {`Are you sure you want to delete "${thingToDelete}"?`}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {deleteNow
            ? 'This item will be deleted immediately'
            : 'This item will not be actually deleted until the project is saved.'}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(false)} color="primary">
          Cancel
        </Button>
        <Button onClick={() => onClose(true)} color="secondary" autoFocus>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { ConfirmationDialog };
