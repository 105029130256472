import React, { FC } from 'react';
import { Select, MenuItem, withStyles } from '@material-ui/core';
import moment from 'moment';
import { getProjectMasterRecord_projectMasterRecord_versions } from '__generated__/getProjectMasterRecord';

type Props = {
  currentVersionId: number;
  onVersionChange: (newVersionId: number) => void;
  versions: getProjectMasterRecord_projectMasterRecord_versions[];
};

const FilledVersionSelect = withStyles((theme) => {
  return {
    root: {
      color: theme.palette.primary.contrastText,
    },
    icon: {
      color: theme.palette.primary.contrastText,
    },
  };
})(Select);

export const VersionSelect: FC<Props> = ({
  currentVersionId,
  onVersionChange,
  versions,
}) => {
  return (
    <FilledVersionSelect
      value={currentVersionId}
      onChange={(e) => onVersionChange(e.target.value as number)}
      color={'white'}
      style={{ marginRight: '32px' }}
      MenuProps={{
        getContentAnchorEl: null,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        style: { marginTop: '4px' },
      }}
      disableUnderline
    >
      {versions
        .sort((a, b) => b.version - a.version)
        .map((version) => {
          return (
            <MenuItem value={version.id} key={version.id}>
              {version.approvedAt ? '*' : ''}
              {version.version} - {version.createdByUser.name} @{' '}
              {moment(+version.createdAt).format('M/D/YYYY h:mmA')}
            </MenuItem>
          );
        })}
    </FilledVersionSelect>
  );
};
