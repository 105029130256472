import numeral from 'numeral';
import { InputFormat } from 'types/input-format';

const formatNumber = (
  number: string | number,
  format: InputFormat,
  isUserInput = true
) => {
  if (!number) {
    return '';
  }
  if (typeof number === 'number') {
    number = `${number}`;
  }

  const parsedNumber = numeral(number);
  if (parsedNumber.value() === null) {
    return '';
  }

  if (format === InputFormat.Percentage && isUserInput) {
    if (!number.includes('%')) {
      return parsedNumber.divide(100).format(format);
    }
  }

  if (format === InputFormat.Money && parsedNumber.value() > 100) {
    return numeral(number).format(InputFormat.MoneyNoDecimal);
  }

  return numeral(number).format(format);
};

export { formatNumber };
