import React from 'react';
import gql from 'graphql-tag';
import { Admin, USER_FRAGMENT } from 'components/admin';
import { useQuery, useApolloClient } from '@apollo/react-hooks';
import { getUsers } from '__generated__/getUsers';
import { UpdateUserVariables, UpdateUser } from '__generated__/UpdateUser';
import { UpdateUserInput, RegisterInput } from '__generated__/globalTypes';
import {
  RegisterUser,
  RegisterUserVariables
} from '__generated__/RegisterUser';
import { DeleteUser, DeleteUserVariables } from '__generated__/DeleteUser';

const GET_USERS = gql`
  query getUsers {
    users {
      ...userFragment
    }
  }
  ${USER_FRAGMENT}
`;

const UPDATE_USER = gql`
  mutation UpdateUser($id: Int!, $input: UpdateUserInput!) {
    updateUser(id: $id, input: $input) {
      user {
        ...userFragment
      }
    }
  }
  ${USER_FRAGMENT}
`;

const REGISTER_USER = gql`
  mutation RegisterUser($input: RegisterInput!) {
    register(input: $input) {
      user {
        ...userFragment
      }
    }
  }
  ${USER_FRAGMENT}
`;

const DELETE_USER = gql`
  mutation DeleteUser($id: Int!) {
    deleteUser(id: $id) {
      ...userFragment
    }
  }
  ${USER_FRAGMENT}
`;

const AdminContainer = () => {
  const { data, error, loading } = useQuery<getUsers>(GET_USERS);
  const apolloClient = useApolloClient();

  const updateUser = (id: number, formValues: UpdateUserInput) => {
    return apolloClient.mutate<UpdateUser, UpdateUserVariables>({
      mutation: UPDATE_USER,
      variables: { id: id, input: formValues }
    });
  };

  const registerUser = (formValues: RegisterInput) => {
    return apolloClient.mutate<RegisterUser, RegisterUserVariables>({
      mutation: REGISTER_USER,
      refetchQueries: [{ query: GET_USERS }],
      variables: { input: formValues }
    });
  };

  const deleteUser = (id: number) => {
    return apolloClient.mutate<DeleteUser, DeleteUserVariables>({
      mutation: DELETE_USER,
      refetchQueries: [{ query: GET_USERS }],
      variables: { id }
    });
  };

  if (error) {
    return <div>Error {error}</div>;
  }
  return (
    !loading &&
    data && (
      <Admin
        users={data.users}
        updateUser={updateUser}
        registerUser={registerUser}
        deleteUser={deleteUser}
      />
    )
  );
};

export { AdminContainer };
