import React, { FC, useEffect } from 'react';
import gql from 'graphql-tag';
import { Login } from 'containers/login';
import {
  getCurrentUser_currentUser,
  getCurrentUser,
} from '__generated__/getCurrentUser';
import { useQuery } from '@apollo/react-hooks';

const CURRENT_USER = gql`
  query getCurrentUser {
    currentUser {
      id
      isAdmin
      email
      name
    }
  }
`;

interface IUserContext {
  currentUser: getCurrentUser_currentUser;
}
export const UserContext = React.createContext<IUserContext>({
  currentUser: null,
});

const Authenticated: FC = ({ children }) => {
  const apolloClient = useQuery<getCurrentUser>(CURRENT_USER, {
    skip: !localStorage.getItem('token'),
  });

  useEffect(() => {
    if (
      window.location.pathname !== '/login' &&
      !apolloClient.loading &&
      !apolloClient.data
    ) {
      window.location.href = '/login';
    }
  }, [apolloClient.data, apolloClient.loading]);

  if (apolloClient.loading) {
    return null;
  }

  return apolloClient.data ? (
    <UserContext.Provider
      value={{ currentUser: apolloClient.data.currentUser }}
    >
      {children}
    </UserContext.Provider>
  ) : (
    <Login />
  );
};

export { Authenticated };
