import React, { FC, useState, useEffect, useMemo, useCallback } from 'react';
import { parseFormValues } from 'utils/parse-form-values';
import { ProjectBar, ProjectTab } from 'components/project-bar';
import moment from 'moment';
import { Controls } from 'components/controls';
import { useRouteMatch, Prompt } from 'react-router';
import { singleProjectView } from 'routes';
import { projectFragment } from '__generated__/projectFragment';
import {
  AssumptionFields,
  FlatAssumptionFields,
} from 'form-definitions/assumptions/assumption-fields';
import { IFormValues } from 'types/form-types';
import { formatDataModelAsFormValues } from 'utils/format-data-model-as-form-values';
import { assumptionFragment } from '__generated__/assumptionFragment';
import { ProjectFields } from 'form-definitions/project-fields';
import { ProjectInput } from '__generated__/globalTypes';
import { getProject_getProject } from '__generated__/getProject';
import { competitorFragment } from '__generated__/competitorFragment';
import { CompetitorFields } from 'form-definitions/competitor-fields';
import { Competitors, ICompetitorWrapper } from 'components/competitors';
import { AggregatedCompetitors } from 'components/aggregate-competitors/aggregate-competitors';
import { unwrapCompetitors } from 'utils/competitor-wrapper';
import { IAssumptionAnnotations } from 'types/assumption-annotations';
import setWith from 'lodash/setWith';
import {
  getProjectMasterRecord_projectMasterRecord,
  getProjectMasterRecord_projectMasterRecord_versions,
} from '__generated__/getProjectMasterRecord';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';
import { IApprovalsState } from 'components/approval-fields';

let GUID = -1;

interface IProjectViewProps {
  project: getProject_getProject;
  saveProject: (project: any) => any;
  saving: boolean;
  lastSuccessfulSave: moment.Moment;
  saveError: string;
  versions: getProjectMasterRecord_projectMasterRecord_versions[];
  onVersionChange: (newVersionId: number) => void;
  projectMasterRecord: getProjectMasterRecord_projectMasterRecord;
}

const ProjectView: FC<IProjectViewProps> = ({
  project,
  saveProject,
  saving,
  lastSuccessfulSave,
  saveError,
  versions,
  onVersionChange,
  projectMasterRecord,
}) => {
  const [assumptions, setAssumptions] = useState<
    IFormValues<assumptionFragment>[]
  >(
    project.assumptions.map((assumption) =>
      formatDataModelAsFormValues(FlatAssumptionFields, assumption)
    )
  );

  const [projectFields, setProjectFields] = useState<
    IFormValues<projectFragment>
  >(formatDataModelAsFormValues(ProjectFields, project));

  const [assumptionNotes, setAssumptionNotes] =
    useState<IAssumptionAnnotations>(project.assumptionNotes);

  const [competitors, setCompetitors] = useState<ICompetitorWrapper[]>(
    project.competitors.map((competitor) => {
      return {
        ...competitor,
        formValues: formatDataModelAsFormValues(CompetitorFields, competitor),
      };
    })
  );

  const [dirty, setDirty] = useState(false);
  useEffect(() => {
    if (dirty) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
    return () => (window.onbeforeunload = undefined);
  }, [dirty]);

  const [tryingToViewVersion, setTryingToViewVersion] = useState(undefined);
  const handleVersionChange = useCallback(
    (newVersionId) => {
      if (dirty) {
        setTryingToViewVersion(newVersionId);
      } else {
        onVersionChange(newVersionId);
      }
    },
    [setTryingToViewVersion, dirty, onVersionChange]
  );
  const cancelVersionChange = useCallback(() => {
    setTryingToViewVersion(undefined);
  }, [setTryingToViewVersion]);
  const confirmVersionChange = useCallback(() => {
    onVersionChange(tryingToViewVersion);
    setTryingToViewVersion(undefined);
  }, [onVersionChange, setTryingToViewVersion, tryingToViewVersion]);

  const parsedAssumptions = useMemo(() => {
    return assumptions.map((assumption) => {
      return parseFormValues(assumption, AssumptionFields.flat());
    });
  }, [assumptions]);

  const handleAssumptionChange = useCallback(
    (assumptionIndex: number, fieldName: string, value: string) => {
      setDirty(true);
      setAssumptions((prevAssumptions: any) => {
        const newState = [...prevAssumptions];
        newState[assumptionIndex] = {
          ...newState[assumptionIndex],
          [fieldName]: value,
        };

        return newState;
      });
    },
    []
  );

  const handleAddAssumption = useCallback(() => {
    setDirty(true);
    const newAssumption: IFormValues<assumptionFragment> =
      formatDataModelAsFormValues(FlatAssumptionFields, {
        id: GUID--,
        name: 'New Assumption',
      } as assumptionFragment);

    setAssumptions((prevAssumptions) => {
      return [...prevAssumptions, newAssumption];
    });
  }, []);

  const handleAssumptionDeleted = useCallback((index) => {
    setDirty(true);

    setAssumptions((prevAssumptions) => {
      return prevAssumptions
        .slice(0, index)
        .concat(prevAssumptions.slice(index + 1));
    });
  }, []);

  const handleAssumptionCloned = useCallback((index) => {
    setDirty(true);

    setAssumptions((prevAssumptions) => {
      const newAssumption: IFormValues<assumptionFragment> = {
        ...prevAssumptions[index],
        name: `Copy of ${prevAssumptions[index].name}`,
        id: `${GUID--}`,
      } as IFormValues<assumptionFragment>;

      return prevAssumptions
        .slice(0, index + 1)
        .concat(newAssumption)
        .concat(prevAssumptions.slice(index + 1));
    });
  }, []);

  const handleCompetitorChange = useCallback(
    (
      competitorIndex: number,
      fieldName: keyof competitorFragment,
      value: any
    ) => {
      setDirty(true);
      setCompetitors((prevCompetitors: ICompetitorWrapper[]) => {
        const newState = [...prevCompetitors];
        if (fieldName === 'unitPrices') {
          newState[competitorIndex].unitPrices = value;
        } else {
          newState[competitorIndex].formValues[fieldName] = value;
        }

        return newState;
      });
    },
    []
  );

  const addNewCompetitor = useCallback(() => {
    setDirty(true);
    const newCompetitor: ICompetitorWrapper = {
      formValues: {
        name: 'Untitled Competitor',
      },
      unitPrices: [],
    } as ICompetitorWrapper;

    setCompetitors((prevCompetitors) => {
      return [...prevCompetitors, newCompetitor];
    });
  }, [setCompetitors]);

  const deleteCompetitor = useCallback(
    (indexToDelete: number) => {
      setDirty(true);
      setCompetitors((prevCompetitors) => {
        return prevCompetitors.filter((_, index) => index !== indexToDelete);
      });
    },
    [setCompetitors]
  );

  const handleProjectChange = useCallback(
    (fieldName: string, value: string) => {
      setDirty(true);
      setProjectFields((prevProjectFields) => {
        return {
          ...prevProjectFields,
          [fieldName]: value,
        };
      });
    },
    []
  );

  const handleAssumptionNoteChange = useCallback(
    (
      fieldName: keyof assumptionFragment,
      assumptionId: string,
      note: string
    ) => {
      setDirty(true);

      setAssumptionNotes((prevAssumptionNotes) => {
        const newAssumptionNotes = { ...prevAssumptionNotes };
        setWith(
          newAssumptionNotes,
          `${fieldName}.${assumptionId}.note`,
          note,
          Object
        );
        return newAssumptionNotes;
      });
    },
    []
  );

  const handleAssumptionColorChange = useCallback(
    (
      fieldName: keyof assumptionFragment,
      assumptionId: string,
      color: string
    ) => {
      setDirty(true);

      setAssumptionNotes((prevAssumptionNotes) => {
        const newAssumptionNotes = { ...prevAssumptionNotes };
        setWith(
          newAssumptionNotes,
          `${fieldName}.${assumptionId}.color`,
          color,
          Object
        );
        return newAssumptionNotes;
      });
    },
    []
  );

  const [approvals, setApprovals] = useState<IApprovalsState>({
    zoningApprovedAt: project.zoningApprovedAt,
    zoningApprovedBy: project.zoningApprovedBy,
    zoningApprovedNotes: project.zoningApprovedNotes,
    reaApprovedAt: project.reaApprovedAt,
    reaApprovedBy: project.reaApprovedBy,
    reaApprovedNotes: project.reaApprovedNotes,
  });

  const handleSave = useCallback(() => {
    console.log(approvals);
    setDirty(false);
    const projectToSave: ProjectInput = {
      ...project,
      ...parseFormValues(projectFields, ProjectFields),
      assumptions: assumptions.map((assumption) => ({
        ...assumption,
        ...parseFormValues(assumption, AssumptionFields.flat()),
      })),
      competitors: unwrapCompetitors(competitors),
      assumptionNotes,
      zoningApprovedBy: approvals.zoningApprovedBy
        ? approvals.zoningApprovedBy.id
        : null,
      zoningApprovedNotes: approvals.zoningApprovedNotes,
      zoningApprovedAt: approvals.zoningApprovedAt,
      reaApprovedBy: approvals.reaApprovedBy
        ? approvals.reaApprovedBy.id
        : null,
      reaApprovedNotes: approvals.reaApprovedNotes,
      reaApprovedAt: approvals.reaApprovedAt,
    };

    saveProject({
      variables: {
        projectInput: projectToSave,
      },
    });
  }, [
    project,
    assumptions,
    competitors,
    projectFields,
    saveProject,
    assumptionNotes,
    approvals,
  ]);

  const route = useRouteMatch<{ view: ProjectTab }>(singleProjectView);

  const [selectedTab, setSelectedTab] = useState<ProjectTab>(route.params.view);

  return (
    <>
      <Prompt
        when={dirty}
        message={(location) =>
          location.pathname.startsWith('/project')
            ? true
            : 'You have unsaved changes, are you sure you want to leave?'
        }
      />
      <Dialog open={tryingToViewVersion}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You have unsaved changes. Viewing a pervious version will cause you
            to lose those changes. Are you sure you want to continue?
          </DialogContentText>
          <DialogActions>
            <Button color="primary" onClick={cancelVersionChange}>
              Cancel
            </Button>
            <Button color="secondary" onClick={confirmVersionChange} autoFocus>
              Discard Changes
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <ProjectBar
        onSave={handleSave}
        saving={saving}
        dirty={dirty}
        lastSuccessfulSave={lastSuccessfulSave}
        project={project}
        selectedTab={selectedTab}
        onTabChange={setSelectedTab}
        versions={versions}
        error={saveError}
        onVersionChange={handleVersionChange}
      />
      <div>
        {selectedTab === ProjectTab.Controls && (
          <Controls
            project={project}
            hasUnsavedChanges={dirty}
            assumptions={assumptions}
            assumptionNotes={assumptionNotes}
            onAssumptionNotesChange={handleAssumptionNoteChange}
            onAssumptionColorChange={handleAssumptionColorChange}
            projectFields={projectFields}
            onProjectFieldChange={handleProjectChange}
            onAssumptionChange={handleAssumptionChange}
            onAssumptionAdded={handleAddAssumption}
            parsedAssumptions={parsedAssumptions}
            onAssumptionDeleted={handleAssumptionDeleted}
            onAssumptionCloned={handleAssumptionCloned}
            approvals={approvals}
            setApprovals={setApprovals}
            projectMasterRecord={projectMasterRecord}
          />
        )}
        {selectedTab === ProjectTab.AggComp && (
          <AggregatedCompetitors competitorWrappers={competitors} />
        )}
        {selectedTab === ProjectTab.Competitors && (
          <Competitors
            competitors={competitors}
            onCompetitorChange={handleCompetitorChange}
            addNewCompetitor={addNewCompetitor}
            deleteCompetitor={deleteCompetitor}
          />
        )}
      </div>
    </>
  );
};

export { ProjectView };
