import { FormDefinition, HeaderType } from 'types/form-types';
import { InputFormat } from 'types/input-format';
import { offerOrPricePaidNetOutparcel } from './purchase-price-assumption-fields';
import { assumptionFragment } from '__generated__/assumptionFragment';

const CostToRemodelFields: FormDefinition<assumptionFragment> = [
  {
    text: 'Cost to Remodel Assumptions',
    type: HeaderType.Section,
  },
  {
    name: 'costPerSqFoot',
    label: 'Cost/Square Foot to Renovate (General)',
    format: InputFormat.Money,
  },
  {
    name: 'costToRenovate',
    label: 'Cost to Renovate (before specific additions below)',
    format: InputFormat.Money,
    calculate: costToRenovate,
  },
  {
    name: 'security',
    label: 'Security',
    format: InputFormat.Money,
  },
  {
    name: 'ceilingAndSprinklers',
    label: 'Ceiling / Sprinklers',
    format: InputFormat.Money,
  },
  {
    name: 'floor',
    label: 'Floor',
    format: InputFormat.Money,
  },
  {
    name: 'roof',
    label: 'Roof',
    format: InputFormat.Money,
  },
  {
    name: 'lights',
    label: 'Lights',
    format: InputFormat.Money,
  },
  {
    name: 'hvac',
    label: 'HVAC',
    format: InputFormat.Money,
  },
  {
    name: 'parkingCustomer',
    label: 'Parking Lot - Customer Parking',
    format: InputFormat.Money,
  },
  {
    text: 'Parking Lot - Rental',
    type: HeaderType.Category,
  },
  {
    name: 'parkingRentalResurface',
    label: 'Resurfacing',
    format: InputFormat.Money,
    nested: true,
  },
  {
    name: 'parkingRentalStriping',
    label: 'Striping',
    format: InputFormat.Money,
    nested: true,
  },
  {
    name: 'parkingRentalSecurity',
    label: 'Security',
    format: InputFormat.Money,
    nested: true,
  },
  {
    name: 'parkingRentalFencing',
    label: 'Fencing',
    format: InputFormat.Money,
    nested: true,
  },
  {
    name: 'parkingRentalTotal',
    label: 'Parking Lot - Rental Total',
    format: InputFormat.Money,
    calculate: parkingRentalTotal,
  },
  {
    name: 'otherRemodelCosts',
    label: 'Other',
    format: InputFormat.Money,
  },
  {
    name: 'totalRemodelCost',
    label: 'Total Remodel Cost',
    format: InputFormat.Money,
    calculate: totalRemodelCost,
  },
  {
    name: 'totalRemodelCostPerSqFt',
    label: 'Total Remodel Cost per Square Foot',
    format: InputFormat.Money,
    calculate: (parsedFormValues) => {
      return (
        totalRemodelCost(parsedFormValues) / parsedFormValues.sizeOfBuilding
      );
    },
  },
  {
    name: 'buildStorageUnitsPerSqFt',
    label: 'Cost/Square Foot to Build Out Storage Units',
    format: InputFormat.Money,
  },
  {
    name: 'costToBuildStorageUnits',
    label: 'Cost to Build Out Storage Units',
    format: InputFormat.Money,
    calculate: costToBuildOutStorageUnits,
  },
  {
    name: 'allInRemodelCost',
    label: 'All in Cost (Purchase Price/Reno/Build Out)',
    format: InputFormat.Money,
    calculate: allInRemodelCost,
  },
  {
    name: 'allInRemodelCostPerSqFt',
    label: 'All in Cost/Square Foot',
    format: InputFormat.Money,
    calculate: (parsedFormValues) => {
      return (
        allInRemodelCost(parsedFormValues) / parsedFormValues.sizeOfBuilding
      );
    },
  },
  {
    name: 'largeFutureCapex',
    label: 'Large Future Capex',
    format: InputFormat.Money,
  },
  {
    label: 'Development Fee (during dev phase)',
    fields: [
      {
        name: 'developmentFeeRate',
        fieldLabel: 'Rate %',
        format: InputFormat.Percentage,
        defaultValue: '5%',
      },
      {
        name: 'developmentFeeMonths',
        fieldLabel: 'Months',
        format: InputFormat.Number,
        defaultValue: '12',
      },
      {
        name: 'developmentFee',
        format: InputFormat.Money,
        calculate: developmentFee,
      },
    ],
  },
  {
    label: 'Guarantee Fee (during dev phase)',
    fields: [
      {
        name: 'guaranteeFeeRate',
        fieldLabel: 'Rate %',
        format: InputFormat.Percentage,
        defaultValue: '5%',
      },
      {
        name: 'guaranteeFeeMonths',
        fieldLabel: 'Months',
        format: InputFormat.Number,
        defaultValue: '6',
      },
      {
        name: 'guaranteeFee',
        format: InputFormat.Money,
        calculate: guaranteeFee,
      },
    ],
  },
  {
    label: 'Interest (during dev phase)',
    fields: [
      {
        name: 'interestRate',
        fieldLabel: 'Rate %',
        format: InputFormat.Percentage,
        defaultValue: '3.5%',
      },
      {
        name: 'interestMonths',
        fieldLabel: 'Months',
        format: InputFormat.Number,
        defaultValue: '6',
      },
      {
        name: 'interest',
        format: InputFormat.Money,
        calculate: interest,
      },
    ],
  },
  {
    name: 'otherDevPhaseCosts',
    label: 'Other Dev Phase Costs (Utilities & Taxes)',
    format: InputFormat.Money,
  },
  {
    name: 'allInCost',
    label: 'All In Cost (w/ Guarantee Fee, Interest, etc)',
    format: InputFormat.Money,
    calculate: allInCost,
  },
  {
    name: 'allInCostPerSqFt',
    format: InputFormat.Money,
    label: 'All in Cost (w/ Guarantee Fee, etc)/Square Foot',
    calculate: (parsedFormValues) => {
      return allInCost(parsedFormValues) / parsedFormValues.sizeOfBuilding;
    },
  },
];

function parkingRentalTotal({
  parkingRentalResurface,
  parkingRentalStriping,
  parkingRentalSecurity,
  parkingRentalFencing,
}: assumptionFragment) {
  return (
    parkingRentalResurface +
    parkingRentalStriping +
    parkingRentalSecurity +
    parkingRentalFencing
  );
}

function costToRenovate({ sizeOfBuilding, costPerSqFoot }: assumptionFragment) {
  return sizeOfBuilding * costPerSqFoot;
}

export function totalRemodelCost(parsedFormValues: assumptionFragment) {
  const {
    security,
    ceilingAndSprinklers,
    floor,
    roof,
    lights,
    hvac,
    parkingCustomer,
    otherRemodelCosts,
  } = parsedFormValues;
  return (
    parkingRentalTotal(parsedFormValues) +
    costToRenovate(parsedFormValues) +
    security +
    ceilingAndSprinklers +
    floor +
    roof +
    lights +
    hvac +
    parkingCustomer +
    otherRemodelCosts
  );
}

export function costToBuildOutStorageUnits(
  parsedFormValues: assumptionFragment
) {
  const { buildStorageUnitsPerSqFt, sizeOfBuilding } = parsedFormValues;

  return buildStorageUnitsPerSqFt * sizeOfBuilding;
}

export function allInRemodelCost(parsedFormValues: assumptionFragment) {
  return (
    offerOrPricePaidNetOutparcel(parsedFormValues) +
    totalRemodelCost(parsedFormValues) +
    costToBuildOutStorageUnits(parsedFormValues)
  );
}

export function developmentFee(parsedFormValues: assumptionFragment) {
  const { developmentFeeRate, developmentFeeMonths } = parsedFormValues;
  const feePerMonth =
    (allInRemodelCost(parsedFormValues) * developmentFeeRate) / 12;

  return feePerMonth * developmentFeeMonths;
}

export function guaranteeFee(parsedFormValues: assumptionFragment) {
  const { guaranteeFeeRate, guaranteeFeeMonths } = parsedFormValues;
  const feePerMonth =
    (allInRemodelCost(parsedFormValues) * guaranteeFeeRate) / 12;

  return feePerMonth * guaranteeFeeMonths;
}

export function interest(parsedFormValues: assumptionFragment) {
  const { interestRate, interestMonths } = parsedFormValues;
  const feePerMonth = (allInRemodelCost(parsedFormValues) * interestRate) / 12;

  return feePerMonth * interestMonths;
}

export function allInCost(parsedFormValues: assumptionFragment) {
  return (
    allInRemodelCost(parsedFormValues) +
    developmentFee(parsedFormValues) +
    guaranteeFee(parsedFormValues) +
    interest(parsedFormValues) +
    parsedFormValues.otherDevPhaseCosts
  );
}

export { CostToRemodelFields };
