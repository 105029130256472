import React, { FC, useCallback, useState, useEffect } from 'react';
import PaletteIcon from '@material-ui/icons/Palette';
import { Popper } from '@material-ui/core';
import { HighlightColors, HighlightColor } from 'types/colors';
import styled from 'styled-components';
import FormatColorResetIcon from '@material-ui/icons/FormatColorReset';

const ColorDot = styled.div<{ rgba: HighlightColor }>`
  background-color: ${props => props.rgba.base};
  &:hover {
    background-color: ${props => props.rgba.hover};
  }
  height: 24px;
  width: 24px;
  border-radius: 50%;
  cursor: pointer;
  &:not(:last-child) {
    margin-right: 8px;
  }
`;

interface IColorPicker {
  onColorSelected: (colorName: string) => void;
}

const ColorPicker: FC<IColorPicker> = ({ onColorSelected }) => {
  const [pickerVisible, setPickerVisible] = useState(false);
  const handleClick: React.MouseEventHandler = useCallback(e => {
    setPickerVisible(true);
  }, []);

  const iconRef = React.useRef();

  return (
    <>
      <PaletteIcon
        onClick={handleClick}
        ref={iconRef}
        style={{ cursor: 'pointer' }}
      />
      <Popper
        style={{ zIndex: 999 }}
        open={pickerVisible}
        anchorEl={iconRef.current}
        disablePortal
      >
        <PopperContents
          setPickerVisible={setPickerVisible}
          onColorSelected={onColorSelected}
        />
      </Popper>
    </>
  );
};

const PopperContents: FC<any> = ({
  setPickerVisible,
  onColorSelected
}: {
  setPickerVisible: (visible: boolean) => void;
  onColorSelected: (colorName: string) => void;
}) => {
  useEffect(() => {
    const hidePicker = () => {
      setPickerVisible(false);
    };
    window.addEventListener('click', hidePicker);
    return () => window.removeEventListener('click', hidePicker);
  }, [setPickerVisible]);

  return (
    <div
      style={{
        backgroundColor: 'white',
        display: 'flex',
        padding: '10px'
      }}
    >
      {Object.entries(HighlightColors).map(([name, rgba]) => {
        return (
          <ColorDot
            key={name}
            onClick={() => onColorSelected(name)}
            rgba={rgba}
          />
        );
      })}
      <FormatColorResetIcon
        style={{ cursor: 'pointer' }}
        onClick={() => onColorSelected('')}
      />
    </div>
  );
};

export { ColorPicker };
