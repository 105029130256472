import React, { FC, useCallback, useContext } from 'react';
import { RouteComponentProps, useHistory } from 'react-router';
import gql from 'graphql-tag';
import { useQuery, useApolloClient } from '@apollo/react-hooks';
import { loadProjectList } from '__generated__/loadProjectList';
import { ProjectList } from 'components/project-list';
import { createNewProject } from '__generated__/createNewProject';
import { ProjectTab } from 'components/project-bar';
import { UserContext } from 'Authenticated';
import { Button } from '@material-ui/core';
import {
  deleteProject,
  deleteProjectVariables,
} from '__generated__/deleteProject';

interface IProjectListContainerProps extends RouteComponentProps {}

const LOAD_PROJECT_LIST = gql`
  query loadProjectList {
    projectMasterRecordCollection {
      id
      markedDeadAt
      hasApprovedVersion
      currentProjectVersion {
        id
        name
        createdAt
      }
    }
  }
`;

const CREATE_NEW_PROJECT = gql`
  mutation createNewProject {
    saveProject(
      input: {
        name: "New Project"
        assumptions: [{ name: "Current" }]
        competitors: []
        assumptionNotes: {}
      }
    ) {
      id
      projectMasterRecord {
        id
      }
    }
  }
`;

const DELETE_PROJECT = gql`
  mutation deleteProject($id: Int!) {
    archiveProjectMasterRecord(id: $id) {
      id
    }
  }
`;

const ProjectListContainer: FC<IProjectListContainerProps> = () => {
  const { data, error, loading } = useQuery<loadProjectList>(
    LOAD_PROJECT_LIST,
    { fetchPolicy: 'network-only' }
  );
  const userContext = useContext(UserContext);
  const client = useApolloClient();
  const history = useHistory();

  const createProjectAndNavigate = useCallback(async () => {
    const result = await client.mutate<createNewProject>({
      mutation: CREATE_NEW_PROJECT,
    });

    history.push(
      `project/${result.data.saveProject.projectMasterRecord.id}/${ProjectTab.Controls}`
    );
  }, [client, history]);

  const deleteProject = useCallback(
    async (id: number) => {
      await client.mutate<deleteProject, deleteProjectVariables>({
        mutation: DELETE_PROJECT,
        variables: {
          id,
        },
        refetchQueries: ['loadProjectList'],
      });
    },
    [client]
  );

  if (loading) return <div>loading</div>;
  if (error) {
    return (
      <div>
        `error: ${error.name} - ${error.message}`
      </div>
    );
  }

  return (
    <>
      <div style={{ paddingLeft: '20px', display: 'flex' }}>
        <div style={{ padding: '10px' }}>
          <Button onClick={createProjectAndNavigate} variant="contained">
            Create New Project
          </Button>
        </div>
        <div style={{ padding: '10px' }}>
          {userContext.currentUser.isAdmin && (
            <Button variant="contained" onClick={() => history.push('/admin')}>
              Manage Users
            </Button>
          )}
        </div>

        <div style={{ padding: '10px' }}>
          <Button
            variant="contained"
            onClick={() => history.push('/excel-uploader')}
          >
            Upload Excel File
          </Button>
        </div>
      </div>
      <div style={{ display: 'flex' }}>
        <div>
          <ProjectList
            projects={data.projectMasterRecordCollection}
            createProjectAndNavigate={createProjectAndNavigate}
            deleteProject={deleteProject}
          />
        </div>
      </div>
    </>
  );
};

export { ProjectListContainer };
