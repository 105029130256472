import { InputFormat } from 'types/input-format';
import { IFormField, IDataModel } from 'types/form-types';

const isNumberInput = <DataModel = IDataModel>(
  field: IFormField<DataModel>
) => {
  return (
    field.format === InputFormat.Money ||
    field.format === InputFormat.Number ||
    field.format === InputFormat.Percentage
  );
};

export { isNumberInput };
