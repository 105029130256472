import { Checkbox, TableCell, TableRow } from '@material-ui/core';
import { UserContext } from 'Authenticated';
import { NotesInput } from 'components/input-row';
import React, { useContext, useState } from 'react';

export interface IApprovalsState {
  zoningApprovedAt: string | null;
  zoningApprovedBy: { id: number; name: string } | null;
  zoningApprovedNotes: string;

  reaApprovedAt: string | null;
  reaApprovedBy: { id: number; name: string } | null;
  reaApprovedNotes: string;
}

interface IApprovalFieldsProps {
  readonly approvals: IApprovalsState;
  readonly setApprovals: React.Dispatch<React.SetStateAction<IApprovalsState>>;
}

export const ApprovalFields = ({
  approvals,
  setApprovals,
}: IApprovalFieldsProps) => {
  const zoningApproved = !!approvals.zoningApprovedAt;
  const reaApproved = !!approvals.reaApprovedAt;
  const { currentUser } = useContext(UserContext);

  return (
    <>
      <TableRow>
        <TableCell>Zoning</TableCell>
        <TableCell>
          <Checkbox
            checked={zoningApproved}
            onChange={(_, checked) =>
              setApprovals((prevState) => {
                return {
                  ...prevState,
                  zoningApprovedAt: checked ? new Date().toISOString() : null,
                  zoningApprovedBy: checked ? currentUser : null,
                };
              })
            }
          />{' '}
          {zoningApproved && `Entered by ${approvals.zoningApprovedBy.name}`}
          {zoningApproved && (
            <NotesInput
              value={approvals.zoningApprovedNotes}
              onChange={(newNotes) => {
                setApprovals((prevState) => {
                  return {
                    ...prevState,
                    zoningApprovedNotes: newNotes,
                  };
                });
              }}
            />
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>REA</TableCell>
        <TableCell>
          <Checkbox
            checked={reaApproved}
            onChange={(_, checked) =>
              setApprovals((prevState) => {
                return {
                  ...prevState,
                  reaApprovedAt: checked ? new Date().toISOString() : null,
                  reaApprovedBy: checked ? currentUser : null,
                };
              })
            }
          />{' '}
          {reaApproved && `Entered by ${approvals.reaApprovedBy.name}`}
          {reaApproved && (
            <NotesInput
              value={approvals.reaApprovedNotes}
              onChange={(newNotes) => {
                setApprovals((prevState) => {
                  return {
                    ...prevState,
                    reaApprovedNotes: newNotes,
                  };
                });
              }}
            />
          )}
        </TableCell>
      </TableRow>
    </>
  );
};
