import { unitPriceFragment } from '__generated__/unitPriceFragment';
import React, { FC, useState, useCallback } from 'react';
import { formatNumber } from 'utils/format-number';
import { InputFormat } from 'types/input-format';
import EditIcon from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import { IconButton, TableRow, TableCell } from '@material-ui/core';
import { UnitPriceForm } from './unit-price-form';
import { ConfirmationDialog } from 'components/comfirmation-dialog';

interface IUnitPriceRow {
  unitPrice: unitPriceFragment;
  onSave: (updatedUnitPrice: unitPriceFragment) => void;
  onDelete: () => void;
}

const UnitPriceRow: FC<IUnitPriceRow> = ({ unitPrice, onSave, onDelete }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);

  const handleSave = useCallback(
    (updatedUnitPrice: unitPriceFragment) => {
      setIsEditing(false);
      onSave(updatedUnitPrice);
    },
    [setIsEditing, onSave]
  );

  const handleDeleteConfirmation = useCallback(
    (confirmed) => {
      if (confirmed) {
        onDelete();
      }
      setIsConfirmingDelete(false);
    },
    [onDelete, setIsConfirmingDelete]
  );

  if (isEditing) {
    return (
      <UnitPriceForm
        unitPrice={unitPrice}
        onSubmit={handleSave}
        focusOnRender={true}
      />
    );
  }

  const sqft = unitPrice.dimension1 * unitPrice.dimension2;
  return (
    <>
      <TableRow>
        <TableCell>
          {unitPrice.dimension1} x {unitPrice.dimension2}
        </TableCell>
        <TableCell>{formatNumber(sqft, InputFormat.Number)}</TableCell>
        <TableCell>
          {formatNumber(unitPrice.price, InputFormat.Money)}
        </TableCell>
        <TableCell>
          {formatNumber((unitPrice.price * 12) / sqft, InputFormat.Money)}
        </TableCell>
        <TableCell>{unitPrice.isWebPrice ? 'yes' : 'no'}</TableCell>
        <TableCell>{unitPrice.numberVacant}</TableCell>
        <TableCell>
          <IconButton onClick={() => setIsEditing(true)} size="small">
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => setIsConfirmingDelete(true)} size="small">
            <Delete />
          </IconButton>
        </TableCell>
      </TableRow>

      <ConfirmationDialog
        open={isConfirmingDelete}
        onClose={handleDeleteConfirmation}
        thingToDelete={`${unitPrice.dimension1} x ${
          unitPrice.dimension2
        } priced at ${formatNumber(unitPrice.price, InputFormat.Money)}`}
      />
    </>
  );
};

export { UnitPriceRow };
