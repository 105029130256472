import React from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Table,
  TableCell,
  TableHead,
  TableBody
} from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router-dom';
import gql from 'graphql-tag';
import { userFragment } from '__generated__/userFragment';
import { UserRow, UserEditRow } from './user-row';
import { FetchResult } from 'apollo-link';
import { UpdateUserInput, RegisterInput } from '__generated__/globalTypes';

export const USER_FRAGMENT = gql`
  fragment userFragment on User {
    id
    name
    email
    isAdmin
  }
`;

const Admin = ({
  users,
  updateUser,
  registerUser,
  deleteUser
}: {
  users: userFragment[];
  updateUser: (id: number, formValues: UpdateUserInput) => Promise<FetchResult>;
  registerUser: (formValues: RegisterInput) => Promise<any>;
  deleteUser: (id: number) => Promise<any>;
}) => {
  const history = useHistory();
  return (
    <>
      <AppBar position="sticky">
        <Toolbar variant="dense">
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => history.push('/')}
          >
            <ArrowBack />
          </IconButton>
          Manage Users
        </Toolbar>
      </AppBar>
      <Table style={{ width: 'auto' }}>
        <TableHead>
          <TableCell>Name</TableCell>
          <TableCell>Email</TableCell>
          <TableCell>Is Admin</TableCell>
          <TableCell>Password</TableCell>
          <TableCell />
        </TableHead>
        <TableBody>
          <UserEditRow onAddNew={registerUser} />
          {users.map(user => {
            return (
              <UserRow
                user={user}
                updateUser={updateUser}
                deleteUser={deleteUser}
              />
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};

export { Admin };
