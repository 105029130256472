import React, { FC, useState } from 'react';

import styles from './index.module.css';
import { competitorFragment } from '__generated__/competitorFragment';
import { NavigationList } from './navigation-list';
import { CompetitorForm } from './competitor-form';
import { IFormValues } from 'types/form-types';

export interface ICompetitorWrapper extends competitorFragment {
  formValues: IFormValues<competitorFragment>;
}

interface ICompetitorProps {
  competitors: ICompetitorWrapper[];
  onCompetitorChange: (
    competitorIndex: number,
    fieldName: keyof competitorFragment,
    value: any
  ) => void;
  addNewCompetitor: () => void;
  deleteCompetitor: (indexToDelete: number) => void;
}

const Competitors: FC<ICompetitorProps> = ({
  competitors,
  onCompetitorChange,
  addNewCompetitor,
  deleteCompetitor
}) => {
  const [selectedCompetitorIndex, setSelectedCompetitorIndex] = useState<
    number
  >();

  if (selectedCompetitorIndex === undefined) {
    setSelectedCompetitorIndex(0);
  }

  const selectedCompetitor = competitors[selectedCompetitorIndex];

  return (
    <div className={styles.container}>
      <div className={styles.list}>
        <NavigationList
          competitors={competitors}
          onAddNewCompetitor={addNewCompetitor}
          onCompetitorChanged={setSelectedCompetitorIndex}
          onDeleteCompetitor={deleteCompetitor}
          selectedCompetitorIndex={selectedCompetitorIndex}
        />
      </div>
      {selectedCompetitor && (
        <CompetitorForm
          competitor={selectedCompetitor}
          onCompetitorChange={(
            fieldName: keyof competitorFragment,
            value: any
          ) => onCompetitorChange(selectedCompetitorIndex, fieldName, value)}
        />
      )}
    </div>
  );
};

export { Competitors };
