import React, { useState, useRef } from 'react';
import xlsx from 'xlsx';
import { CompetitorInput, UnitPriceInput } from '__generated__/globalTypes';
import { assumptionFragment } from '__generated__/assumptionFragment';
import { formatDataModelAsFormValues } from 'utils/format-data-model-as-form-values';
import { FlatAssumptionFields } from 'form-definitions/assumptions/assumption-fields';
import { parseFormValues } from 'utils/parse-form-values';
import {
  theoreticalMaxAllInCost,
  operatingIncomeOverAllInCost,
  theoreticalMaxPurchasePrice,
  totalAnnualOperatingIncome,
} from 'form-definitions/assumptions/outputs';
import { allInCost } from 'form-definitions/assumptions/cost-to-remodel-fields';
import { useApolloClient } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { ProjectTab } from 'components/project-bar';
import {
  createProjectFromUpload,
  createProjectFromUploadVariables,
} from '__generated__/createProjectFromUpload';
import ApolloClient from 'apollo-client';

const CREATE_PROJECT_FROM_UPLOAD = gql`
  mutation createProjectFromUpload($projectInput: ProjectInput) {
    saveProject(input: $projectInput) {
      id
      name
      projectMasterRecord {
        id
      }
    }
  }
`;

const getCellValue = (
  sheet: xlsx.WorkSheet,
  column: string,
  rowNumber: number
): any => {
  if (sheet[`${column}${rowNumber}`]) {
    return sheet[`${column}${rowNumber}`].v;
  } else {
    return null;
  }
};

const getValue = (sheet: xlsx.WorkSheet, address: string): any => {
  if (sheet[`${address}`]) {
    return sheet[`${address}`].v;
  } else {
    return null;
  }
};

const getAssumptionInColumn = (
  controlSheet: xlsx.WorkSheet,
  column: string,
  name: string
): assumptionFragment => {
  const cell = (column: string, rowNumber: number) => {
    return getCellValue(controlSheet, column, rowNumber);
  };
  const row = (rowNumber: number): number => {
    if (controlSheet[`${column}${rowNumber}`]) {
      return cell(column, rowNumber) as number;
    } else {
      return null;
    }
  };

  const intendedManager = (manager: string) => {
    switch (manager) {
      case 'Cube Smart':
        return 'cube_smart';
      case 'Extra Space Storage':
        return 'extra_space';
      case 'Life Storage':
        return 'life_storage';
      default:
        return manager;
    }
  };
  const assumption: assumptionFragment = {
    id: undefined as any,
    name,
    sizeOfBuilding: row(11),
    askingPrice: row(11),
    maximumPrice: row(16),
    offerOrPricePaid: row(19) || row(24),
    outparcelValue: row(22),
    netRentableSqFootage: row(27),
    costPerSqFoot: row(31),
    security: row(36),
    ceilingAndSprinklers: row(38),
    floor: row(40),
    roof: row(42),
    lights: row(44),
    hvac: row(46),
    parkingCustomer: row(48),
    parkingRentalResurface: row(52),
    parkingRentalStriping: row(54),
    parkingRentalSecurity: row(56),
    parkingRentalFencing: row(58),
    otherRemodelCosts: row(62),
    buildStorageUnitsPerSqFt: row(68),
    developmentFeeRate: cell('C', 78) as number,
    developmentFeeMonths: cell('E', 78) as number,
    guaranteeFeeRate: cell('C', 80) as number,
    guaranteeFeeMonths: cell('E', 80) as number,
    interestRate: cell('C', 82) as number,
    interestMonths: cell('E', 82) as number,
    otherDevPhaseCosts: row(84),
    propertyTax: row(92),
    propertyTaxRatePerSqFt: cell('C', 92) as number,
    propertyTaxMonths: cell('E', 92) as number,
    insurance: row(94),
    insurancePerSqFt: cell('C', 94) as number,
    insuranceMonths: cell('E', 94) as number,
    commonAreaMaintenance: row(96),
    commonAreaMaintenancePerSqFt: cell('C', 96) as number,
    commonAreaMaintenanceMonths: cell('E', 96) as number,
    utilities: row(98),
    utilitiesPerSqFt: cell('C', 98) as number,
    utilitiesMonths: cell('E', 98) as number,
    otherExpenses: row(100),
    operatingExpenses: row(102),
    parkingLotRentalExpenses: row(104),
    managementCompanyFee: cell('G', 108) as number,
    annualMinimumFee: cell('M', 108) as number,
    ratePerMonth10by10: row(113),
    parkingRentPerSpace: row(119),
    parkingNumberOfSpaces: row(121),
    assumedOccupancyParking: row(123),
    parkingRevenue: row(125),
    annualRetailProductsRevenuePercentage: row(127),
    annualInsuranceRevenuePercentage: row(129),
    upfrontSignageCash: row(131),
    intendedManager: intendedManager(`${row(133)}`),
    assumedOccupancyStorage: row(138),
    largeFutureCapex: cell('G', 76) as number,
    otherRevenue: row(144),
    targetReturn: row(159),
  };

  const formValues = formatDataModelAsFormValues(
    FlatAssumptionFields,
    assumption
  );
  const parsedValues = parseFormValues(formValues, FlatAssumptionFields) as any;
  const checkPairs: [string, number, number, number][] = [
    [
      'Total Operating Income',
      totalAnnualOperatingIncome(parsedValues),
      row(150),
      5,
    ],
    ['All In Cost', allInCost(parsedValues), row(86), 1],
    [
      'Theoretical Max All In Cost',
      theoreticalMaxAllInCost(parsedValues),
      row(161),
      5,
    ],
    [
      'TF Return Proxy',
      operatingIncomeOverAllInCost(parsedValues),
      row(157),
      0.005,
    ],
    [
      'Theoretical Max Purchase Price',
      theoreticalMaxPurchasePrice(parsedValues),
      row(164),
      5,
    ],
  ];
  checkPairs.forEach(([name, calculated, reference, tolerance]) => {
    const isInTolerance =
      calculated < reference + tolerance && calculated > reference - tolerance;
    if (!isInTolerance) {
      throw new Error(`${name} does not match, ${calculated} != ${reference}`);
    }
  });

  return assumption;
};

function getUnitPricesInRow(
  val: Function,
  row: number,
  cc: boolean
): UnitPriceInput[] {
  const unitPrices = [];
  const rowVal = val('A' + row);
  if (rowVal) {
    if (`${rowVal}`.includes('x')) {
      const [length, width] = rowVal.split('x');
      const webPrice = val('D' + row);
      const phonePrice = val('H' + row);
      const inPersonPrice = val('L' + row);
      const numVacant = val('P' + row);
      if (webPrice) {
        unitPrices.push({
          dimension1: parseFloat(length),
          dimension2: parseFloat(width),
          isClimateControlled: cc,
          isWebPrice: true,
          numberVacant: numVacant,
          price: webPrice,
        });
      }
      if (phonePrice) {
        unitPrices.push({
          dimension1: parseFloat(length),
          dimension2: parseFloat(width),
          isClimateControlled: cc,
          isWebPrice: false,
          numberVacant: numVacant,
          price: phonePrice,
        });
      }
      if (inPersonPrice) {
        unitPrices.push({
          dimension1: parseFloat(length),
          dimension2: parseFloat(width),
          isClimateControlled: cc,
          isWebPrice: false,
          numberVacant: numVacant,
          price: inPersonPrice,
        });
      }
    }
  }
  return unitPrices;
}

function getCompetitor(sheet: xlsx.WorkSheet): CompetitorInput {
  const val = (address: string) => getValue(sheet, address);

  const competitor: CompetitorInput = {
    name: val('D3') || '',
    address: val('D5') || '',
    telephone: val('D7') || '',
    listingWebsite: val('D9') || '',
    distanceFromProposedSite: val('D11'),
    climateControlledPercentage: val('D13'),
    totalSquareFootage: val('D15'),
    unitsVacant: val('D17'),
  };

  const unitPrices: UnitPriceInput[] = [];
  let startOfCCUnitSizes, startOfNonCCUnitSizes;
  for (let row = 17; row < 100; row++) {
    const rowVal = val('A' + row);
    if (rowVal && rowVal.match(/standard unit sizes/i)) {
      if (!startOfCCUnitSizes) {
        startOfCCUnitSizes = row;
      } else {
        startOfNonCCUnitSizes = row;
        break;
      }
    }
  }

  for (let row = startOfCCUnitSizes; row < startOfNonCCUnitSizes; row++) {
    unitPrices.push(...getUnitPricesInRow(val, row, true));
  }

  for (let row = startOfNonCCUnitSizes; row < 100; row++) {
    unitPrices.push(...getUnitPricesInRow(val, row, false));
  }

  competitor.unitPrices = unitPrices;

  return competitor;
}

const ASSUMPTION_COLUMNS = [
  { column: 'G', name: 'Current' },
  { column: 'I', name: 'Prior Est 1' },
  { column: 'K', name: 'Prior Est 2' },
  { column: 'M', name: 'Prior Est 3' },
];

const uploadFile = (
  file: File,
  apolloClient: ApolloClient<object>
): Promise<createProjectFromUpload> => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onloadend = async (e) => {
      try {
        const fileName = file.name;
        const indexOfExtensionDot = file.name.lastIndexOf('.');

        const fileData = new Uint8Array(e.target.result as ArrayBuffer);
        const workbook = xlsx.read(fileData, { type: 'array' });

        const controlsSheetName = workbook.SheetNames[0];
        if (!controlsSheetName.match(/controls/i)) {
          throw new Error(
            `First sheet is not Controls got: ${controlsSheetName}`
          );
        }

        const controlsSheet = workbook.Sheets[controlsSheetName];

        const assumptions = [];
        for (const column of ASSUMPTION_COLUMNS) {
          // The the estimate has a building size, assume it's filled in
          if (getCellValue(controlsSheet, column.column, 11)) {
            assumptions.push(
              getAssumptionInColumn(controlsSheet, column.column, column.name)
            );
          }
        }

        const competitorSheetNames = [];
        for (const sheetName of workbook.SheetNames) {
          if (sheetName.match(/Controls|AggComp/i)) {
            continue;
          }
          if (sheetName.match(/Not Using/i)) {
            break;
          }
          competitorSheetNames.push(sheetName);
        }

        let competitors: CompetitorInput[] = [];
        for (const competitorName of competitorSheetNames) {
          competitors.push(getCompetitor(workbook.Sheets[competitorName]));
        }

        const result = await apolloClient.mutate<
          createProjectFromUpload,
          createProjectFromUploadVariables
        >({
          mutation: CREATE_PROJECT_FROM_UPLOAD,
          variables: {
            projectInput: {
              name: fileName.slice(0, indexOfExtensionDot),
              address: getCellValue(controlsSheet, 'G', 5) || '',
              listingWebsite: getCellValue(controlsSheet, 'G', 7) || '',
              assumptions: assumptions,
              competitors: competitors,
              assumptionNotes: {},
            },
          },
        });

        resolve(result.data);
      } catch (e) {
        reject(e);
      }
    };

    fileReader.readAsArrayBuffer(file);
  });
};

const ExcelUploader: React.FC = () => {
  const apolloClient = useApolloClient();
  const fileInputRef = useRef<HTMLInputElement>();
  const [uploadedFiles, setUploadedFiles] = useState<createProjectFromUpload[]>(
    []
  );
  const [failedFiles, setFailedFiles] = useState<
    { fileName: string; error: Error }[]
  >([]);
  const [loading, setLoading] = useState(false);

  const handleUpload = async (e: React.MouseEvent<HTMLButtonElement>) => {
    setLoading(true);
    const files = fileInputRef.current.files;
    const successes = [];
    const failures = [];
    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      try {
        const result = await uploadFile(file, apolloClient);
        successes.push(result);
      } catch (e) {
        failures.push({ fileName: file.name, error: e });
      }
    }
    fileInputRef.current.value = '';
    setUploadedFiles(uploadedFiles.concat(successes));
    setFailedFiles(failedFiles.concat(failures));
    setLoading(false);
  };

  return (
    <div style={{ padding: '10px' }}>
      <div style={{ paddingBottom: '10px' }}>
        <input
          type="file"
          ref={fileInputRef}
          multiple={true}
          accept=".xls,.xlsx,.xlsm"
          disabled={loading}
        />
      </div>
      <div style={{ paddingBottom: '10px' }}>
        <button type="button" onClick={handleUpload} disabled={loading}>
          Upload
        </button>
      </div>
      {loading && <div style={{ paddingBottom: '10px' }}>Loading...</div>}
      <div>
        <h4>Files Uploaded</h4>
        <ul>
          {uploadedFiles.map((projectResult) => {
            return (
              <li>
                <a
                  href={`project/${projectResult.saveProject.projectMasterRecord.id}/${ProjectTab.Controls}`}
                  target={'_blank'}
                >
                  {projectResult.saveProject.name}
                </a>
              </li>
            );
          })}
        </ul>
        <h4>Failures</h4>
        <ul>
          {failedFiles.map((failure) => {
            return (
              <li>
                {failure.fileName} - {failure.error.message}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export { ExcelUploader };
