import React, { FC } from 'react';

import { IFormHeader, HeaderType } from 'types/form-types';
import { TableRow, TableCell, Theme, withStyles } from '@material-ui/core';

interface IHeaderRowProps {
  formHeader: IFormHeader;
  additionalCells?: JSX.Element[];
}

const SectionHeaderRow = withStyles((theme: Theme) => {
  return {
    root: {
      backgroundColor: theme.palette.primary.light
    }
  };
})(TableRow);

const HeaderRow: FC<IHeaderRowProps> = ({ additionalCells, formHeader }) => {
  const RowComponent =
    formHeader.type === HeaderType.Section ? SectionHeaderRow : TableRow;

  return (
    <RowComponent>
      <TableCell colSpan={additionalCells ? 1 : 99} variant="head">
        {formHeader.text}
      </TableCell>
      {additionalCells}
      <TableCell style={{ width: '100%' }} />
    </RowComponent>
  );
};

export { HeaderRow };
