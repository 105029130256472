import { FormDefinition, HeaderType } from 'types/form-types';
import { InputFormat } from 'types/input-format';
import { assumptionFragment } from '__generated__/assumptionFragment';

const RevenueFields: FormDefinition<assumptionFragment> = [
  {
    text: 'Revenue Assumptions',
    type: HeaderType.Section
  },
  {
    name: 'ratePerMonth10by10',
    label: 'Rate per Month (10x10 unit)',
    format: InputFormat.Money
  },
  {
    name: 'impliedRatePerSqFtFor10by10',
    label: 'Implied Rate per SF for Standard 10x10 unit',
    calculate: impliedRatePerSqFtFor10by10,
    format: InputFormat.Money
  },
  {
    name: 'numberOfStandardUnits',
    label: 'Number of Standard Units (calculated)',
    calculate: numberOfStandardUnits,
    format: InputFormat.Number
  },
  {
    name: 'parkingRentPerSpace',
    label: 'Parkling Lot Rental - Rent/Space',
    format: InputFormat.Money
  },
  {
    name: 'parkingNumberOfSpaces',
    label: 'Number of Parking Spaces',
    format: InputFormat.Number
  },
  {
    name: 'assumedOccupancyParking',
    label: 'Assumed Occupancy (Parking)',
    format: InputFormat.Percentage
  },
  {
    name: 'parkingRevenue',
    label: 'Parking Revenue',
    format: InputFormat.Percentage
  },
  {
    name: 'annualRetailProductsRevenuePercentage',
    label: 'Annual Retail Products Revenue (% of Rental Inc)',
    format: InputFormat.Percentage
  },
  {
    name: 'annualInsuranceRevenuePercentage',
    label: 'Annual Insurance Revenue (% of Rental Inc)',
    format: InputFormat.Percentage
  },
  {
    name: 'upfrontSignageCash',
    label: 'Upfront Cash from Manager for Signage',
    format: InputFormat.Money
  },
  {
    name: 'intendedManager',
    label: 'Intended Manager',
    format: InputFormat.String,
    options: [
      {
        name: 'Cube Smart',
        value: 'cube_smart'
      },
      {
        name: 'Extra Space Storage',
        value: 'extra_space'
      },
      {
        name: 'Life Storage',
        value: 'life_storage'
      }
    ]
  },
  {
    name: 'assumedOccupancyStorage',
    label: 'Assumed Occupancy (Mini Storage)',
    format: InputFormat.Percentage
  }
];

function impliedRatePerSqFtFor10by10(parsedFormValues: assumptionFragment) {
  const ratePerYear = parsedFormValues.ratePerMonth10by10 * 12;
  return ratePerYear / 100;
}

export function numberOfStandardUnits(parsedFormValues: assumptionFragment) {
  const { sizeOfBuilding, netRentableSqFootage } = parsedFormValues;
  const rentableSqFt = sizeOfBuilding * netRentableSqFootage;

  return Math.round(rentableSqFt / 100);
}

export { RevenueFields };
