import gql from 'graphql-tag';

const UNIT_PRICE_FRAGMENT = gql`
  fragment unitPriceFragment on UnitPrice {
    id
    dimension1
    dimension2
    isClimateControlled
    isWebPrice
    price
    numberVacant
    isParking
  }
`;

export { UNIT_PRICE_FRAGMENT };
