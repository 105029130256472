import React, { FC } from 'react';
import { IFormValues, HeaderType } from 'types/form-types';
import { Table, TableBody, TableCell, Button } from '@material-ui/core';
import { assumptionFragment } from '__generated__/assumptionFragment';
import { FlatAssumptionFields } from 'form-definitions/assumptions/assumption-fields';
import { InputRow } from 'components/input-row';
import { isInputField } from 'utils/is-input-field';
import { isMultiInputField } from 'utils/is-multi-input-field';
import { isCalculatedField } from 'utils/is-calculated-field';
import { HeaderRow } from 'components/header-row';
import { AssumptionName } from './assumption-name';
import { IAssumptionAnnotations } from 'types/assumption-annotations';
import { isSelectField } from 'utils/is-select-field';

interface IAssumptionTabsProps {
  assumptionList: IFormValues[];
  parsedAssumptionList: assumptionFragment[];
  onAssumptionChange: (
    assumptionIndex: number,
    fieldName: string,
    value: string
  ) => void;
  onAssumptionAdded: () => void;
  onAssumptionDeleted: (index: number) => void;
  onAssumptionCloned: (index: number) => void;
  assumptionNotes: IAssumptionAnnotations;
  onAssumptionNotesChange: (
    fieldName: keyof assumptionFragment,
    assumptionId: string,
    note: string
  ) => void;
  onAssumptionColorChange?: (
    fieldName: keyof assumptionFragment,
    assumptionId: string,
    color: string
  ) => void;
}

const AssumptionTabs: FC<IAssumptionTabsProps> = ({
  assumptionList,
  parsedAssumptionList,
  onAssumptionChange,
  onAssumptionAdded,
  onAssumptionDeleted,
  onAssumptionCloned,
  assumptionNotes,
  onAssumptionNotesChange,
  onAssumptionColorChange,
}) => {
  return (
    <React.Fragment>
      <Table size="small">
        <TableBody>
          {FlatAssumptionFields.map((field, index) => {
            if (
              isInputField(field) ||
              isMultiInputField(field) ||
              isCalculatedField(field) ||
              isSelectField(field)
            ) {
              return (
                <InputRow
                  formField={field}
                  parsedFormValues={parsedAssumptionList}
                  formValues={assumptionList}
                  onFieldChange={(fieldName, value, index) => {
                    onAssumptionChange(index, fieldName, value);
                  }}
                  onAssumptionNotesChange={onAssumptionNotesChange}
                  onAssumptionColorChange={onAssumptionColorChange}
                  key={index}
                  supportsNotes
                  assumptionNotes={assumptionNotes}
                />
              );
            } else {
              return (
                <HeaderRow
                  key={index}
                  formHeader={field}
                  additionalCells={
                    field.type === HeaderType.Section && [
                      ...assumptionList.map((assumption, assumptionIndex) => (
                        <AssumptionName
                          key={assumption.id}
                          value={assumption['name']}
                          onChange={(value) =>
                            onAssumptionChange(assumptionIndex, 'name', value)
                          }
                          onDelete={() => onAssumptionDeleted(assumptionIndex)}
                          onClone={() => onAssumptionCloned(assumptionIndex)}
                        />
                      )),
                      <TableCell key={'add-new'} variant="head">
                        <Button onClick={onAssumptionAdded} variant="contained">
                          New
                        </Button>
                      </TableCell>,
                    ]
                  }
                />
              );
            }
          })}
        </TableBody>
      </Table>
    </React.Fragment>
  );
};

export { AssumptionTabs };
