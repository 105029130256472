import { loadProjectList_projectMasterRecordCollection } from '__generated__/loadProjectList';
import React, { FC, useEffect, useState, useCallback, useMemo } from 'react';
import { TextField, Typography } from '@material-ui/core';
import { ConfirmationDialog } from 'components/comfirmation-dialog';
import { useDebounce } from 'react-use';
import { CardList } from './card-list';

interface IProjectListProps {
  projects: loadProjectList_projectMasterRecordCollection[];
  createProjectAndNavigate: () => void;
  deleteProject: (projectId: number) => void;
}

const ProjectList: FC<IProjectListProps> = ({
  projects,
  createProjectAndNavigate,
  deleteProject,
}) => {
  const [filteredProjects, setFilteredProjects] = useState<
    loadProjectList_projectMasterRecordCollection[]
  >([]);
  useEffect(() => {
    setFilteredProjects(projects);
  }, [projects]);

  const [search, setSearch] = useState('');

  useDebounce(
    () => {
      setFilteredProjects(
        projects
          .filter((project) => {
            return project.currentProjectVersion.name
              .toLowerCase()
              .includes(search.toLowerCase());
          })
          .sort(
            (a, b) =>
              +b.currentProjectVersion.createdAt -
              +a.currentProjectVersion.createdAt
          )
      );
    },
    500,
    [search]
  );

  const unapprovedProjects = useMemo(() => {
    return filteredProjects.filter(
      (project) => !project.hasApprovedVersion && !project.markedDeadAt
    );
  }, [filteredProjects]);
  const approvedProjects = useMemo(() => {
    return filteredProjects.filter(
      (project) => project.hasApprovedVersion && !project.markedDeadAt
    );
  }, [filteredProjects]);
  const deadProjects = useMemo(() => {
    return filteredProjects.filter((project) => project.markedDeadAt);
  }, [filteredProjects]);

  const [projectToDelete, setProjectToDelete] =
    useState<loadProjectList_projectMasterRecordCollection>(undefined);

  const handleDeleteConfirmation = useCallback(
    (confirmed) => {
      if (confirmed) {
        deleteProject(projectToDelete.id);
      }
      setProjectToDelete(undefined);
    },
    [deleteProject, projectToDelete]
  );

  return (
    <>
      <ConfirmationDialog
        open={!!projectToDelete}
        onClose={handleDeleteConfirmation}
        thingToDelete={
          projectToDelete && projectToDelete.currentProjectVersion.name
        }
        deleteNow={true}
      />
      <div>
        <div style={{ paddingLeft: '30px', paddingTop: '10px' }}>
          <TextField
            label={'Search'}
            onChange={(e) => setSearch(e.target.value)}
            fullWidth
          />
        </div>
      </div>
      <div style={{ display: 'flex', paddingTop: '10px' }}>
        <div style={{ minWidth: '400px' }}>
          <Typography variant="h6" style={{ textAlign: 'center' }}>
            In Progress
          </Typography>
          <CardList
            projects={unapprovedProjects}
            onDelete={setProjectToDelete}
          />
        </div>
        <div style={{ minWidth: '400px' }}>
          <Typography variant="h6" style={{ textAlign: 'center' }}>
            Approved
          </Typography>
          <CardList projects={approvedProjects} onDelete={setProjectToDelete} />
        </div>
        <div style={{ minWidth: '400px' }}>
          <Typography variant="h6" style={{ textAlign: 'center' }}>
            Dead
          </Typography>
          <CardList projects={deadProjects} onDelete={setProjectToDelete} />
        </div>
      </div>
    </>
  );
};

export { ProjectList };
