import React, { FC, useCallback, useMemo } from 'react';
import { unitPriceFragment } from '__generated__/unitPriceFragment';
import { UnitPriceRow } from './unit-price-row';
import { UnitPriceForm } from './unit-price-form';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';

interface IUnitPricesProps {
  unitPrices: unitPriceFragment[];
  setUnitPrices: (unitPrices: unitPriceFragment[]) => void;
}

interface IWrappedUnitPrice {
  unitPrice: unitPriceFragment;
  originalIndex: number;
}

const sortUnitPrices = (a: IWrappedUnitPrice, b: IWrappedUnitPrice) =>
  a.unitPrice.dimension1 * a.unitPrice.dimension2 -
  b.unitPrice.dimension1 * b.unitPrice.dimension2;

const UnitPrices: FC<IUnitPricesProps> = ({
  unitPrices = [],
  setUnitPrices,
}) => {
  const addNewUnitPrice = useCallback(
    (newUnitPrice: unitPriceFragment) => {
      setUnitPrices([...unitPrices, newUnitPrice]);
    },
    [unitPrices, setUnitPrices]
  );

  const deleteUnitPrice = useCallback(
    (indexToDelete) => {
      setUnitPrices([
        ...unitPrices.slice(0, indexToDelete),
        ...unitPrices.slice(indexToDelete + 1, unitPrices.length),
      ]);
    },
    [unitPrices, setUnitPrices]
  );

  const updateUnitPrice = useCallback(
    (updatedUnitPrice: unitPriceFragment, index: number) => {
      const newUnitPrices = [...unitPrices];
      newUnitPrices[index] = updatedUnitPrice;
      setUnitPrices(newUnitPrices);
    },
    [unitPrices, setUnitPrices]
  );

  const wrappedUnitPrices = useMemo(() => {
    return unitPrices.map((unitPrice, index) => ({
      unitPrice,
      originalIndex: index,
    }));
  }, [unitPrices]);

  return (
    <>
      <Table size="small">
        <TableBody>
          <UnitPriceForm onSubmit={addNewUnitPrice} />
        </TableBody>
      </Table>
      <h3>Climate Controlled</h3>
      <UnitPriceTable
        unitPrices={wrappedUnitPrices
          .filter(
            (unitPrice) =>
              !unitPrice.unitPrice.isParking &&
              unitPrice.unitPrice.isClimateControlled
          )
          .sort(sortUnitPrices)}
        saveUnitPrice={updateUnitPrice}
        deleteUnitPrice={deleteUnitPrice}
      />
      <h3>Not Climate Controlled</h3>
      <UnitPriceTable
        unitPrices={wrappedUnitPrices
          .filter(
            (unitPrice) =>
              !unitPrice.unitPrice.isParking &&
              !unitPrice.unitPrice.isClimateControlled
          )
          .sort(sortUnitPrices)}
        saveUnitPrice={updateUnitPrice}
        deleteUnitPrice={deleteUnitPrice}
      />
      <h3>Parking</h3>
      <UnitPriceTable
        unitPrices={wrappedUnitPrices
          .filter((unitPrice) => unitPrice.unitPrice.isParking)
          .sort(sortUnitPrices)}
        saveUnitPrice={updateUnitPrice}
        deleteUnitPrice={deleteUnitPrice}
      />
    </>
  );
};

interface IUnitPriceTable {
  unitPrices: IWrappedUnitPrice[];
  saveUnitPrice: (updatedUnitPrice: unitPriceFragment, index: number) => void;
  deleteUnitPrice: (index: number) => void;
}

let GUID = -1;

const UnitPriceTable: FC<IUnitPriceTable> = ({
  unitPrices,
  saveUnitPrice,
  deleteUnitPrice,
}) => {
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Dimensions</TableCell>
          <TableCell>Sq Ft</TableCell>
          <TableCell>Price</TableCell>
          <TableCell>$/SF</TableCell>
          <TableCell>Is Web Price</TableCell>
          <TableCell># Vacant</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {unitPrices.map(({ unitPrice, originalIndex }) => (
          <UnitPriceRow
            unitPrice={unitPrice}
            key={unitPrice.id || GUID--}
            onSave={(updatedUnitPrice) =>
              saveUnitPrice(updatedUnitPrice, originalIndex)
            }
            onDelete={() => deleteUnitPrice(originalIndex)}
          />
        ))}
      </TableBody>
    </Table>
  );
};

export { UnitPrices };
