import gql from 'graphql-tag';
import { COMPETITOR_FRAGMENT } from './competitor-fragment';
import { ASSUMPTION_FRAGMENT } from './assumption-fragment';
const PROJECT_FRAGMENT = gql`
  fragment projectFragment on Project {
    id
    name
    listingWebsite
    address
    assumptionNotes
    approvedAt
    zoningApprovedAt
    zoningApprovedBy {
      id
      name
    }
    zoningApprovedNotes
    reaApprovedAt
    reaApprovedBy {
      id
      name
    }
    reaApprovedNotes
    competitors {
      ...competitorFragment
    }
    assumptions {
      ...assumptionFragment
    }
  }
  ${COMPETITOR_FRAGMENT}
  ${ASSUMPTION_FRAGMENT}
`;

export { PROJECT_FRAGMENT };
