import {
  List,
  Button,
  IconButton,
  ListItem,
  ListItemText
} from '@material-ui/core';
import React, { FC, useCallback, useState } from 'react';
import NavigateNext from '@material-ui/icons/NavigateNext';
import DeleteIcon from '@material-ui/icons/Delete';
import { ConfirmationDialog } from 'components/comfirmation-dialog';
import { ICompetitorWrapper } from '.';

interface INavigationListProps {
  competitors: ICompetitorWrapper[];
  selectedCompetitorIndex: number;
  onAddNewCompetitor: () => void;
  onDeleteCompetitor: (indexToDelete: number) => void;
  onCompetitorChanged: (selectedCompetitorIndex: number) => void;
}

let GUID = -1;

const NavigationList: FC<INavigationListProps> = ({
  onAddNewCompetitor,
  onDeleteCompetitor,
  onCompetitorChanged,
  competitors,
  selectedCompetitorIndex
}) => {
  const [potentialIndexToDelete, setPotentialIndexToDelete] = useState<
    number | null
  >(null);

  const handleAddNewCompetitor = useCallback(() => {
    const currentNumberOfCompetitors = competitors.length;
    onAddNewCompetitor();
    onCompetitorChanged(currentNumberOfCompetitors);
  }, [onAddNewCompetitor, onCompetitorChanged, competitors]);

  const handleDeleteCompetitor = useCallback(
    indexToDelete => {
      if (indexToDelete === selectedCompetitorIndex) {
        onCompetitorChanged(0);
      } else if (indexToDelete < selectedCompetitorIndex) {
        onCompetitorChanged(selectedCompetitorIndex - 1);
      }
      onDeleteCompetitor(indexToDelete);
    },
    [selectedCompetitorIndex, onCompetitorChanged, onDeleteCompetitor]
  );

  const handleDeleteConfirmation = useCallback(
    confirmed => {
      if (confirmed) {
        handleDeleteCompetitor(potentialIndexToDelete);
      }

      setPotentialIndexToDelete(null);
    },
    [potentialIndexToDelete, setPotentialIndexToDelete, handleDeleteCompetitor]
  );

  return (
    <>
      <ConfirmationDialog
        open={potentialIndexToDelete !== null}
        onClose={handleDeleteConfirmation}
        thingToDelete={
          potentialIndexToDelete !== null &&
          competitors[potentialIndexToDelete].formValues.name
        }
      />
      <List>
        <Button onClick={handleAddNewCompetitor} variant="contained">
          Add New Competitor
        </Button>
        {competitors.map((competitor, index) => {
          return (
            <div
              key={competitor.id || GUID--}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <IconButton
                size="small"
                onClick={() => setPotentialIndexToDelete(index)}
              >
                <DeleteIcon />
              </IconButton>
              <ListItem
                button
                selected={index === selectedCompetitorIndex}
                onClick={() => onCompetitorChanged(index)}
              >
                <ListItemText primary={competitor.formValues.name} />
                <NavigateNext />
              </ListItem>
            </div>
          );
        })}
      </List>
    </>
  );
};

export { NavigationList };
