import { PurchasePriceAssumptionFields } from 'form-definitions/assumptions/purchase-price-assumption-fields';
import { CostToRemodelFields } from 'form-definitions/assumptions/cost-to-remodel-fields';
import { ExpenseFields } from './expense-fields';
import { RevenueFields } from './revenue-fields';
import { Outputs } from './outputs';

const AssumptionFields = [
  PurchasePriceAssumptionFields,
  CostToRemodelFields,
  ExpenseFields,
  RevenueFields,
  Outputs
];

export const FlatAssumptionFields = AssumptionFields.flat();

export { AssumptionFields };
