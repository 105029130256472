import React, { FC, useMemo } from 'react';
import { ICompetitorWrapper } from '../competitors';
import { unwrapCompetitors } from 'utils/competitor-wrapper';
import {
  bucketUnitPrices,
  BucketUnitPricesResult,
} from 'utils/bucket-unit-prices';
import { unitPriceFragment } from '__generated__/unitPriceFragment';

import { AggCompTable } from './agg-comp-table';
import { Table, TableRow, TableBody } from '@material-ui/core';
import { PlainTableCell } from 'components/plain-table-cell';
import { calculatePossibleModel } from 'utils/calculate-price-model';
import { formatNumber } from 'utils/format-number';
import { InputFormat } from 'types/input-format';

interface IAggregatedCompetitorsProps {
  competitorWrappers: ICompetitorWrapper[];
}

const AggregatedCompetitors: FC<IAggregatedCompetitorsProps> = ({
  competitorWrappers,
}) => {
  const competitors = useMemo(() => {
    return unwrapCompetitors(competitorWrappers);
  }, [competitorWrappers]);

  const totalSquareFootageAvailable = useMemo(() => {
    return competitors.reduce(
      (totalSqFt, competitor) => totalSqFt + competitor.totalSquareFootage,
      0
    );
  }, [competitors]);

  const totalUnitsVacant = useMemo(() => {
    return competitors.reduce(
      (totalUnitsVacant, competitor) =>
        totalUnitsVacant + competitor.unitsVacant,
      0
    );
  }, [competitors]);

  const allUnitPrices: unitPriceFragment[] = useMemo(() => {
    return competitors.reduce(
      (unitPrices, competitor) => unitPrices.concat(competitor.unitPrices),
      []
    );
  }, [competitors]);

  const climateControlledUnits = useMemo(() => {
    return allUnitPrices.filter(
      (unitPrice) => !unitPrice.isParking && unitPrice.isClimateControlled
    );
  }, [allUnitPrices]);

  const notClimateControlledUnits = useMemo(() => {
    return allUnitPrices.filter(
      (unitPrice) => !unitPrice.isParking && !unitPrice.isClimateControlled
    );
  }, [allUnitPrices]);

  const parkingUnits = useMemo(() => {
    return allUnitPrices.filter((unitPrice) => unitPrice.isParking);
  }, [allUnitPrices]);

  const climateControlledBuckets = useMemo(() => {
    return bucketUnitPrices(climateControlledUnits);
  }, [climateControlledUnits]);

  const notClimateControlledBuckets = useMemo(() => {
    return bucketUnitPrices(notClimateControlledUnits);
  }, [notClimateControlledUnits]);

  const parkingBuckets: BucketUnitPricesResult = useMemo(() => {
    return bucketUnitPrices(parkingUnits, []);
  }, [parkingUnits]);

  const climateControlledModel = useMemo(() => {
    return calculatePossibleModel(climateControlledUnits);
  }, [climateControlledUnits]);

  const notClimateControlledModel = useMemo(() => {
    return calculatePossibleModel(notClimateControlledUnits);
  }, [notClimateControlledUnits]);

  console.log(notClimateControlledUnits, notClimateControlledModel);

  return (
    <>
      <Table style={{ width: 'auto', marginBottom: '20px' }} size="small">
        <TableBody>
          <TableRow>
            <PlainTableCell variant="head">
              Total Square Footage Available
            </PlainTableCell>
            <PlainTableCell>{totalSquareFootageAvailable}</PlainTableCell>
          </TableRow>
          <TableRow>
            <PlainTableCell variant="head">Total Units Vacant</PlainTableCell>
            <PlainTableCell>{totalUnitsVacant}</PlainTableCell>
          </TableRow>
          <TableRow>
            <PlainTableCell variant="head">
              Possible Price Model (CC){' '}
            </PlainTableCell>
            <PlainTableCell>
              {formatNumber(
                climateControlledModel.basePrice,
                InputFormat.Money
              )}{' '}
              +{' '}
              {formatNumber(climateControlledModel.perSqFt, InputFormat.Money)}
              /SF (
              {formatNumber(
                climateControlledModel.confidence,
                InputFormat.Percentage,
                false
              )}{' '}
              Match)
            </PlainTableCell>
          </TableRow>
          <TableRow>
            <PlainTableCell variant="head">
              Possible Price Model (NCC){' '}
            </PlainTableCell>
            <PlainTableCell>
              {formatNumber(
                notClimateControlledModel.basePrice,
                InputFormat.Money
              )}{' '}
              +{' '}
              {formatNumber(
                notClimateControlledModel.perSqFt,
                InputFormat.Money
              )}
              /SF (
              {formatNumber(
                notClimateControlledModel.confidence,
                InputFormat.Percentage,
                false
              )}{' '}
              Match)
            </PlainTableCell>
          </TableRow>
        </TableBody>
      </Table>
      <AggCompTable
        headerLabel="Climate Controlled (CC)"
        bucketedUnitPrices={climateControlledBuckets}
      />
      <AggCompTable
        headerLabel="Not Climate Controlled (NCC)"
        bucketedUnitPrices={notClimateControlledBuckets}
      />
      <AggCompTable headerLabel="Parking" bucketedUnitPrices={parkingBuckets} />
    </>
  );
};

export { AggregatedCompetitors };
