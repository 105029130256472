import { FormDefinition, HeaderType, InputWidth } from 'types/form-types';
import { InputFormat } from 'types/input-format';
import { competitorFragment } from '__generated__/competitorFragment';

const CompetitorFields: FormDefinition<competitorFragment> = [
  {
    name: 'name',
    label: 'Name',
    format: InputFormat.String,
    inputWidth: InputWidth.LongText,
    colSpan: 3,
  },
  {
    name: 'address',
    label: 'Address',
    format: InputFormat.String,
    inputWidth: InputWidth.LongText,
    colSpan: 3,
  },
  {
    name: 'telephone',
    label: 'Telephone',
    format: InputFormat.String,
  },
  {
    name: 'listingWebsite',
    label: 'Listing Website/Source',
    format: InputFormat.String,
    inputWidth: InputWidth.LongText,
    colSpan: 3,
  },
  {
    name: 'distanceFromProposedSite',
    label: 'Distance from Proposed Site (miles)',
    format: InputFormat.Number,
  },
  {
    name: 'climateControlledPercentage',
    label: 'Climate Controlled %',
    format: InputFormat.Percentage,
  },
  {
    name: 'totalSquareFootage',
    label: 'Total Square Footage',
    format: InputFormat.Number,
  },
  {
    name: 'unitsVacant',
    label: 'Units Vacant',
    format: InputFormat.Number,
  },
];

export { CompetitorFields };
