import { FormDefinition, HeaderType } from 'types/form-types';
import { numberOfStandardUnits } from './revenue-fields';
import { InputFormat } from 'types/input-format';
import { totalExpensesBeforeManagmentFee } from './expense-fields';
import {
  allInCost,
  totalRemodelCost,
  costToBuildOutStorageUnits,
  developmentFee,
  guaranteeFee,
  interest,
} from './cost-to-remodel-fields';
import { assumptionFragment } from '__generated__/assumptionFragment';

const Outputs: FormDefinition<assumptionFragment> = [
  { text: 'Outputs', type: HeaderType.Section },
  {
    name: 'miniStorageRevenue',
    label: 'Mini Storage Revenue',
    nested: true,
    calculate: miniStorageRevenue,
    format: InputFormat.Money,
  },
  {
    name: 'parkingRentalRevenue',
    label: 'Parking Rental Revenue',
    nested: true,
    calculate: parkingRentalRevenue,
    format: InputFormat.Money,
  },
  {
    name: 'otherRevenue',
    label: 'Other Revenue',
    nested: true,
    format: InputFormat.Money,
  },
  {
    name: 'totalAnnualRevenue',
    label: 'Total Annual Revenue',
    format: InputFormat.Money,
    calculate: totalAnnualRevenue,
  },
  {
    name: 'miniStorageOperatingExpenses',
    label: 'Mini Storage Operating Expenses',
    nested: true,
    format: InputFormat.Money,
    calculate: miniStorageOperatingExpenses,
  },
  {
    name: 'parkingLotRentalExpenses',
    label: 'Parking Rental Expenses',
    nested: true,
    format: InputFormat.Money,
  },
  {
    name: 'nominalManagementFee',
    label: 'Management Fee',
    nested: true,
    format: InputFormat.Money,
    calculate: managementFee,
  },
  {
    name: 'totalAnnualExpensesWithManagementFee',
    label: 'Total Annual Expenses (w/ Mgmt Fee)',
    format: InputFormat.Money,
    calculate: totalAnnualExpensesWithManagementFee,
  },
  {
    name: 'totalAnnualOperatingIncome',
    label: 'Total Annual Operating Income',
    format: InputFormat.Money,
    calculate: totalAnnualOperatingIncome,
  },
  {
    text: 'Per SF',
  },
  {
    name: 'totalAnnualRevenuePerSqFt',
    label: 'Total Annual Revenue/SF',
    format: InputFormat.Money,
    calculate: totalAnnualRevenuePerSqFt,
    nested: true,
  },
  {
    name: 'totalAnnualExpensesWithManagmentFeePerSqFt',
    label: 'Total Annual Expenses (w/ MgmtFee)/SF',
    format: InputFormat.Money,
    calculate: totalAnnualExpensesPerSqFt,
    nested: true,
  },
  {
    name: 'totalAnnualOperatingIncomePerSqFt',
    label: 'Total Annual Operating Income/SF',
    format: InputFormat.Money,
    calculate: totalAnnualOperatingIncomePerSqFt,
    nested: true,
  },
  {
    name: 'operatingIncomeOverAllInCost',
    label: 'Operating Income / All in Cost (TF Return Proxy)',
    format: InputFormat.Percentage,
    calculate: operatingIncomeOverAllInCost,
  },
  {
    name: 'targetReturn',
    label: 'Target Return',
    format: InputFormat.Percentage,
  },
  {
    name: 'theoreticalMaxAllINCost',
    label: 'Theoretical Max "All In" Cost',
    format: InputFormat.Money,
    calculate: theoreticalMaxAllInCost,
  },
  {
    name: 'theoreticalMaxAllINCostPerSqFt',
    label: 'Theoretical Max "All In" Cost Per Square Foot',
    format: InputFormat.Money,
    calculate: theoreticalMaxAllInCostPerSqFt,
    nested: true,
  },
  {
    name: 'theoreticalMaxPurchasePrice',
    label: 'Theoretical Max Purchase Price',
    format: InputFormat.Money,
    calculate: theoreticalMaxPurchasePrice,
  },
  {
    name: 'theoreticalMaxPurchasePricePerSqFt',
    label: 'Theoretical Max Purchase Price Per Square Foot',
    format: InputFormat.Money,
    calculate: theoreticalMaxPurchasePricePerSqFt,
    nested: true,
  },
];

function miniStorageRevenue(parsedFormValues: assumptionFragment) {
  const { ratePerMonth10by10, assumedOccupancyStorage } = parsedFormValues;
  return (
    numberOfStandardUnits(parsedFormValues) *
    ratePerMonth10by10 *
    assumedOccupancyStorage *
    12
  );
}

function parkingRentalRevenue(parsedFormValues: assumptionFragment) {
  const {
    parkingRentPerSpace,
    parkingNumberOfSpaces,
    assumedOccupancyParking,
  } = parsedFormValues;

  return (
    parkingRentPerSpace * parkingNumberOfSpaces * assumedOccupancyParking * 12
  );
}

function totalAnnualRevenue(parsedFormValues: assumptionFragment) {
  const { otherRevenue } = parsedFormValues;
  return (
    miniStorageRevenue(parsedFormValues) +
    parkingRentalRevenue(parsedFormValues) +
    otherRevenue
  );
}

function miniStorageOperatingExpenses(parsedFormValues: assumptionFragment) {
  const { parkingLotRentalExpenses } = parsedFormValues;

  return (
    totalExpensesBeforeManagmentFee(parsedFormValues) - parkingLotRentalExpenses
  );
}

function managementFee(parsedFormValues: assumptionFragment) {
  const { managementCompanyFee, annualMinimumFee } = parsedFormValues;

  return Math.max(
    totalAnnualRevenue(parsedFormValues) * managementCompanyFee,
    annualMinimumFee
  );
}

function totalAnnualExpensesWithManagementFee(
  parsedFormValues: assumptionFragment
) {
  const { parkingLotRentalExpenses } = parsedFormValues;

  return (
    miniStorageOperatingExpenses(parsedFormValues) +
    parkingLotRentalExpenses +
    managementFee(parsedFormValues)
  );
}

export function totalAnnualOperatingIncome(
  parsedFormValues: assumptionFragment
) {
  return (
    totalAnnualRevenue(parsedFormValues) -
    totalAnnualExpensesWithManagementFee(parsedFormValues)
  );
}

function totalAnnualRevenuePerSqFt(parsedFormValues: assumptionFragment) {
  const { sizeOfBuilding } = parsedFormValues;
  return totalAnnualRevenue(parsedFormValues) / sizeOfBuilding;
}

function totalAnnualExpensesPerSqFt(parsedFormValues: assumptionFragment) {
  const { sizeOfBuilding } = parsedFormValues;
  return (
    totalAnnualExpensesWithManagementFee(parsedFormValues) / sizeOfBuilding
  );
}

function totalAnnualOperatingIncomePerSqFt(
  parsedFormValues: assumptionFragment
) {
  const { sizeOfBuilding } = parsedFormValues;
  return totalAnnualOperatingIncome(parsedFormValues) / sizeOfBuilding;
}

export function operatingIncomeOverAllInCost(
  parsedFormValues: assumptionFragment
) {
  return (
    totalAnnualOperatingIncome(parsedFormValues) / allInCost(parsedFormValues)
  );
}

export function theoreticalMaxAllInCost(parsedFormValues: assumptionFragment) {
  const { targetReturn } = parsedFormValues;
  return totalAnnualOperatingIncome(parsedFormValues) / targetReturn;
}

function theoreticalMaxAllInCostPerSqFt(parsedFormValues: assumptionFragment) {
  const { sizeOfBuilding } = parsedFormValues;
  return theoreticalMaxAllInCost(parsedFormValues) / sizeOfBuilding;
}

export function theoreticalMaxPurchasePrice(
  parsedFormValues: assumptionFragment
) {
  const { otherDevPhaseCosts } = parsedFormValues;
  return (
    theoreticalMaxAllInCost(parsedFormValues) -
    totalRemodelCost(parsedFormValues) -
    costToBuildOutStorageUnits(parsedFormValues) -
    developmentFee(parsedFormValues) -
    guaranteeFee(parsedFormValues) -
    interest(parsedFormValues) -
    otherDevPhaseCosts
  );
}

function theoreticalMaxPurchasePricePerSqFt(
  parsedFormValues: assumptionFragment
) {
  const { sizeOfBuilding } = parsedFormValues;
  return theoreticalMaxPurchasePrice(parsedFormValues) / sizeOfBuilding;
}

export { Outputs };
