import { ICompetitorWrapper } from 'components/competitors';
import { competitorFragment } from '__generated__/competitorFragment';
import { omit } from 'lodash';
import { parseFormValues } from './parse-form-values';
import { CompetitorFields } from 'form-definitions/competitor-fields';

const unwrapCompetitors = (
  competitors: ICompetitorWrapper[]
): competitorFragment[] => {
  return competitors.map(
    (competitor: ICompetitorWrapper): competitorFragment => ({
      ...omit(competitor, 'formValues'),
      ...parseFormValues(competitor.formValues, CompetitorFields)
    })
  );
};

export { unwrapCompetitors };
