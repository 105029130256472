import regression from 'regression';
import { unitPriceFragment } from '__generated__/unitPriceFragment';

interface IRegression {
  basePrice: number;
  perSqFt: number;
  confidence: number;
}

const calculatePossibleModel = (
  unitPrices: unitPriceFragment[]
): IRegression => {
  const result = regression.linear(
    unitPrices.map((unitPrice) => [
      unitPrice.dimension1 * unitPrice.dimension2,
      unitPrice.price,
    ])
  );

  return {
    basePrice: result.equation[1],
    perSqFt: result.equation[0] * 12,
    confidence: result.r2,
  };
};

export { calculatePossibleModel };
