import { InputFormat } from 'types/input-format';

export enum InputWidth {
  LongText = '600px',
  Full = '100%',
}

interface IBaseFormField {
  label?: string;
  fieldLabel?: string;
  nested?: boolean;
  format: InputFormat;
  colSpan?: number;
  inputWidth?: InputWidth;
  defaultValue?: string;
}

export interface IDataModel {
  [key: string]: any;
}

export interface ICalculatedFormField<
  DataModel extends IDataModel = IDataModel
> extends IBaseFormField {
  name: string;
  calculate: (formValues: DataModel) => number;
}

export interface IEditableFormField<DataModel extends IDataModel = IDataModel>
  extends IBaseFormField {
  name: keyof DataModel;
  recalculate?: {
    key: keyof DataModel;
    calculate: (formValues: DataModel, newValue: number) => number;
  }[];
}

export interface ISelectFormField<DataModel extends IDataModel = IDataModel>
  extends IBaseFormField {
  name: keyof DataModel;
  options: {
    value: string;
    name: string;
  }[];
}

export type IFormField<DataModel extends IDataModel = IDataModel> =
  | ICalculatedFormField<DataModel>
  | IEditableFormField<DataModel>
  | ISelectFormField<DataModel>;

export interface IFormMultiField<DataModel extends IDataModel = IDataModel> {
  label: string;
  nested?: boolean;
  fields: IFormField<DataModel>[];
}

export interface IFormHeader {
  text: string;
  type?: HeaderType;
}

export enum HeaderType {
  Section,
  Category,
}

export type FormDefinition<DataModel extends IDataModel = IDataModel> = Array<
  FieldDefinition<DataModel>
>;

export type FieldDefinition<DataModel extends IDataModel = IDataModel> =
  | ICalculatedFormField<DataModel>
  | IEditableFormField<DataModel>
  | ISelectFormField
  | IFormHeader
  | IFormMultiField<DataModel>;

export type IFormValues<DataModel extends IDataModel = IDataModel> = {
  [key in keyof DataModel]: string;
};
