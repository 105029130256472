import { IEditableFormField } from 'types/form-types';
import { unitPriceFragment } from '__generated__/unitPriceFragment';
import { InputFormat } from 'types/input-format';

export const UnitPriceFields: IEditableFormField<unitPriceFragment>[] = [
  {
    fieldLabel: 'Length',
    name: 'dimension1',
    format: InputFormat.Number,
  },
  {
    fieldLabel: 'Width',
    name: 'dimension2',
    format: InputFormat.Number,
  },
  {
    fieldLabel: 'Price',
    name: 'price',
    format: InputFormat.Money,
  },
  {
    fieldLabel: 'CC? (Y/N)',
    name: 'isClimateControlled',
    format: InputFormat.Boolean,
  },
  {
    fieldLabel: 'Web? (Y/N)',
    name: 'isWebPrice',
    format: InputFormat.Boolean,
  },
  {
    fieldLabel: 'Parking? (Y/N)',
    name: 'isParking',
    format: InputFormat.Boolean,
  },
  {
    fieldLabel: '# Vacant',
    name: 'numberVacant',
    format: InputFormat.Number,
  },
];
