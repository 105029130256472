import React, { FC, useCallback, useContext, useMemo } from 'react';
import { FormSection } from 'components/form-section';
import { AssumptionTabs } from 'components/assumption-tabs';
import { ProjectFields } from 'form-definitions/project-fields';
import { assumptionFragment } from '__generated__/assumptionFragment';
import { IFormValues } from 'types/form-types';
import { projectFragment } from '__generated__/projectFragment';
import { Table, TableBody, Button } from '@material-ui/core';
import { IAssumptionAnnotations } from 'types/assumption-annotations';
import { UserContext } from 'Authenticated';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import {
  approveProjectVersion,
  approveProjectVersionVariables,
} from '__generated__/approveProjectVersion';
import { getProject_getProject } from '__generated__/getProject';
import { ApprovalFields, IApprovalsState } from 'components/approval-fields';
import { getProjectMasterRecord_projectMasterRecord } from '__generated__/getProjectMasterRecord';
import {
  markProjectDead,
  markProjectDeadVariables,
} from '__generated__/markProjectDead';
import {
  undoMarkProjectDead,
  undoMarkProjectDeadVariables,
} from '__generated__/undoMarkProjectDead';

interface IControlsProps {
  projectFields: IFormValues<projectFragment>;
  project: getProject_getProject;
  hasUnsavedChanges: boolean;
  assumptions: IFormValues<assumptionFragment>[];
  onProjectFieldChange: (fieldName: string, value: string) => void;
  onAssumptionChange: (
    assumptionIndex: number,
    fieldName: string,
    value: string
  ) => void;
  onAssumptionAdded: () => void;
  onAssumptionDeleted: (index: number) => void;
  onAssumptionCloned: (index: number) => void;
  parsedAssumptions: assumptionFragment[];
  assumptionNotes: IAssumptionAnnotations;
  onAssumptionNotesChange: (
    fieldName: keyof assumptionFragment,
    assumptionId: string,
    note: string
  ) => void;
  onAssumptionColorChange: (
    fieldName: keyof assumptionFragment,
    assumptionId: string,
    color: string
  ) => void;
  approvals: IApprovalsState;
  setApprovals: React.Dispatch<React.SetStateAction<IApprovalsState>>;
  projectMasterRecord: getProjectMasterRecord_projectMasterRecord;
}

const APPROVE_VERSION = gql`
  mutation approveProjectVersion($id: Int!) {
    approveProjectVersion(id: $id) {
      id
      approvedAt
    }
  }
`;

const MARK_PROJECT_DEAD = gql`
  mutation markProjectDead($projectMasterRecordId: Int!) {
    markProjectDead(projectMasterRecordId: $projectMasterRecordId) {
      id
      markedDeadAt
    }
  }
`;

const UNDO_MARK_PROJECT_DEAD = gql`
  mutation undoMarkProjectDead($projectMasterRecordId: Int!) {
    undoMarkProjectDead(projectMasterRecordId: $projectMasterRecordId) {
      id
      markedDeadAt
    }
  }
`;

const Controls: FC<IControlsProps> = ({
  projectFields,
  project,
  hasUnsavedChanges,
  assumptions,
  onProjectFieldChange,
  onAssumptionChange,
  onAssumptionAdded,
  onAssumptionDeleted,
  onAssumptionCloned,
  parsedAssumptions,
  assumptionNotes,
  onAssumptionNotesChange,
  onAssumptionColorChange,
  approvals,
  setApprovals,
  projectMasterRecord,
}) => {
  const userContext = useContext(UserContext);
  const [approveVersion, result] = useMutation<
    approveProjectVersion,
    approveProjectVersionVariables
  >(APPROVE_VERSION);

  const [markProjectDead] = useMutation<
    markProjectDead,
    markProjectDeadVariables
  >(MARK_PROJECT_DEAD);

  const [undoMarkProjectDead] = useMutation<
    undoMarkProjectDead,
    undoMarkProjectDeadVariables
  >(UNDO_MARK_PROJECT_DEAD);

  const onApproveClick = useCallback(() => {
    approveVersion({ variables: { id: project.id } });
  }, [project.id, approveVersion]);

  const onMarkDead = useCallback(() => {
    if (!!projectMasterRecord.markedDeadAt) {
      undoMarkProjectDead({
        variables: { projectMasterRecordId: projectMasterRecord.id },
      });
    } else {
      markProjectDead({
        variables: { projectMasterRecordId: projectMasterRecord.id },
      });
    }
  }, [markProjectDead, undoMarkProjectDead, projectMasterRecord]);

  const approveButtonText = useMemo(() => {
    if (hasUnsavedChanges) {
      return 'Must save before approving';
    } else if (project.approvedAt) {
      return 'Already Approved';
    } else {
      return 'Mark Approved';
    }
  }, [project.approvedAt, hasUnsavedChanges]);

  return (
    <div style={{ position: 'relative' }}>
      {userContext.currentUser.isAdmin && (
        <Button
          onClick={onApproveClick}
          disabled={result.loading || !!project.approvedAt || hasUnsavedChanges}
          style={{ position: 'absolute', right: '32px', top: '15px' }}
        >
          {approveButtonText}
        </Button>
      )}
      {userContext.currentUser.isAdmin && (
        <Button
          onClick={onMarkDead}
          disabled={result.loading || !!project.approvedAt || hasUnsavedChanges}
          style={{ position: 'absolute', right: '32px', top: '50px' }}
        >
          {!!projectMasterRecord.markedDeadAt
            ? 'Undo Mark Project Dead'
            : 'Mark Project Dead'}
        </Button>
      )}
      <Table style={{ width: 'auto' }} size="small">
        <TableBody>
          <FormSection
            formValues={projectFields}
            fieldDefinitions={ProjectFields}
            onFieldChange={onProjectFieldChange}
          />
          <ApprovalFields approvals={approvals} setApprovals={setApprovals} />
        </TableBody>
      </Table>
      <AssumptionTabs
        assumptionNotes={assumptionNotes}
        onAssumptionNotesChange={onAssumptionNotesChange}
        onAssumptionColorChange={onAssumptionColorChange}
        assumptionList={assumptions}
        parsedAssumptionList={parsedAssumptions}
        onAssumptionChange={onAssumptionChange}
        onAssumptionAdded={onAssumptionAdded}
        onAssumptionCloned={onAssumptionCloned}
        onAssumptionDeleted={onAssumptionDeleted}
      />
    </div>
  );
};

export { Controls };
