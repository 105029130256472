import React, { FC } from 'react';
import { IFormValues, FormDefinition } from 'types/form-types';
import { InputRow } from 'components/input-row';

import { isInputField } from 'utils/is-input-field';
import { HeaderRow } from 'components/header-row';
import { isMultiInputField } from 'utils/is-multi-input-field';
import { isCalculatedField } from 'utils/is-calculated-field';
import { isSelectField } from 'utils/is-select-field';

interface IFormSectionProps {
  formValues: IFormValues | IFormValues[];
  parsedFormValues?: object;
  onFieldChange: (fieldName: string, value: string) => void;
  fieldDefinitions: FormDefinition<any>;
}

const FormSection: FC<IFormSectionProps> = ({
  fieldDefinitions,
  formValues,
  parsedFormValues = {},
  onFieldChange
}) => {
  return (
    <React.Fragment>
      {fieldDefinitions.map((field, index) => {
        if (
          isInputField(field) ||
          isMultiInputField(field) ||
          isCalculatedField(field) ||
          isSelectField(field)
        ) {
          return (
            <InputRow
              formField={field}
              parsedFormValues={parsedFormValues}
              formValues={formValues}
              onFieldChange={onFieldChange}
              key={index}
            />
          );
        } else {
          return <HeaderRow key={index} formHeader={field} />;
        }
      })}
    </React.Fragment>
  );
};

export { FormSection };
