import React, { FC, useState, useEffect, useMemo } from 'react';
import {
  IFormField,
  IFormValues,
  IFormMultiField,
  IDataModel,
  InputWidth,
} from 'types/form-types';
import { FormField } from 'form-field';
import { isMultiInputField } from 'utils/is-multi-input-field';
import { TableRow, TableCell } from '@material-ui/core';
import NoteIcon from '@material-ui/icons/NoteAdd';
import { InputCell } from 'components/input-cell';
import { InputFormat } from 'types/input-format';
import { assumptionFragment } from '__generated__/assumptionFragment';
import { IAssumptionAnnotations } from 'types/assumption-annotations';
import { ColorPicker } from 'components/color-picker';
import { FocusableTableCell } from 'components/focusable-table-cell';
import { PlainTableCell } from 'components/plain-table-cell';

interface IInputRowProps {
  formField: IFormField<any> | IFormMultiField<any>;
  parsedFormValues: IDataModel | IDataModel[];
  formValues: IFormValues | IFormValues[];
  onFieldChange?: (fieldName: string, value: string, index: number) => void;
  supportsNotes?: boolean;
  assumptionNotes?: IAssumptionAnnotations;
  onAssumptionNotesChange?: (
    fieldName: keyof assumptionFragment,
    assumptionId: string,
    note: string
  ) => void;
  onAssumptionColorChange?: (
    fieldName: keyof assumptionFragment,
    assumptionId: string,
    color: string
  ) => void;
}
const InputRow: FC<IInputRowProps> = ({
  formField,
  parsedFormValues,
  formValues,
  onFieldChange,
  supportsNotes,
  assumptionNotes,
  onAssumptionNotesChange,
  onAssumptionColorChange,
}) => {
  const { label, nested } = formField;
  const [shouldShowNotes, setShouldShowNotes] = useState(false);
  const fields = isMultiInputField(formField) ? formField.fields : [formField];
  const formValuesList = Array.isArray(formValues) ? formValues : [formValues];
  const parsedFormValuesList = Array.isArray(parsedFormValues)
    ? parsedFormValues
    : [parsedFormValues];

  const notesVisible = supportsNotes && shouldShowNotes;

  const TableCellComponent = notesVisible ? PlainTableCell : TableCell;

  const lastFieldName = useMemo(
    () => fields[fields.length - 1].name as keyof assumptionFragment,
    [fields]
  );

  const notes =
    (assumptionNotes && assumptionNotes[lastFieldName as string]) || {};

  const hasNotes = useMemo(() => {
    for (let key in notes) {
      if (notes[key] && notes[key].note) {
        return true;
      }
    }
    return false;
  }, [notes]);

  useEffect(() => {
    if (hasNotes) {
      setShouldShowNotes(true);
    }
  }, []);

  return (
    <>
      <TableRow>
        {/* height 1px is a hack to make height 100% work on the inner div */}
        <TableCellComponent
          style={{
            verticalAlign: 'top',
            height: '1px',
            whiteSpace: 'nowrap',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              justifyContent: 'space-around',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {nested && <span style={{ paddingRight: '20px' }} />}
              {supportsNotes && (
                <NoteIcon
                  style={{
                    cursor: 'pointer',
                    color: hasNotes ? 'orange' : 'gray',
                  }}
                  onClick={() => setShouldShowNotes(!shouldShowNotes)}
                />
              )}

              {label}
            </div>
          </div>
        </TableCellComponent>

        {formValuesList.map((formValues, index) => {
          return (
            <FocusableTableCell
              style={{
                minWidth: '200px',
              }}
              key={index}
              plain={notesVisible}
            >
              {({ focused }) => {
                return (
                  <>
                    {fields.map((field) => {
                      return (
                        <div
                          key={`${field.name as string}`}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            position: 'relative',
                          }}
                        >
                          {focused && onAssumptionColorChange && (
                            <div
                              style={{
                                position: 'absolute',
                                left: '-26px',
                                height: '24px',
                              }}
                            >
                              <ColorPicker
                                onColorSelected={(newColor) =>
                                  onAssumptionColorChange(
                                    field.name as keyof assumptionFragment,
                                    formValues.id,
                                    newColor
                                  )
                                }
                              />
                            </div>
                          )}
                          <div style={{ flexGrow: 1 }}>
                            <FormField
                              formField={field}
                              parsedFormValues={parsedFormValuesList[index]}
                              formValues={formValues}
                              onFieldChange={(
                                fieldName: string,
                                value: string
                              ) => onFieldChange(fieldName, value, index)}
                              color={
                                assumptionNotes &&
                                assumptionNotes[field.name as string] &&
                                assumptionNotes[field.name as string][
                                  formValues.id
                                ] &&
                                assumptionNotes[field.name as string][
                                  formValues.id
                                ].color
                              }
                            />
                          </div>
                        </div>
                      );
                    })}
                  </>
                );
              }}
            </FocusableTableCell>
          );
        })}
      </TableRow>
      {notesVisible && (
        <TableRow>
          <TableCell
            style={{
              verticalAlign: 'top',
              height: '1px',
              whiteSpace: 'nowrap',
            }}
          >
            {nested && <span style={{ paddingRight: '20px' }} />}
            <NotesInput
              value={notes['root'] && notes['root'].note}
              onChange={(newNote) =>
                onAssumptionNotesChange(lastFieldName, 'root', newNote)
              }
            />
          </TableCell>
          {formValuesList.map((formValues, index) => {
            return (
              <TableCell
                key={index}
                style={{
                  verticalAlign: 'top',
                  height: '1px',
                  whiteSpace: 'nowrap',
                }}
              >
                <NotesInput
                  value={notes[formValues.id] && notes[formValues.id].note}
                  onChange={(newNote) =>
                    onAssumptionNotesChange(
                      lastFieldName,
                      formValues.id,
                      newNote
                    )
                  }
                />
              </TableCell>
            );
          })}
        </TableRow>
      )}
    </>
  );
};

export const NotesInput = ({
  value,
  onChange,
}: {
  value: string;
  onChange: (newValue: string) => void;
}) => {
  return (
    <InputCell
      formField={{
        format: InputFormat.String,
        fieldLabel: 'Notes',
        name: 'notes',
        inputWidth: InputWidth.Full,
      }}
      value={value || ''}
      onChange={onChange}
      multiline
      rows={2}
      rowsMax={5}
    />
  );
};

export { InputRow };
