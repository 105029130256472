import React, { FC } from 'react';
import { BucketUnitPricesResult } from 'utils/bucket-unit-prices';
import { formatNumber } from 'utils/format-number';
import { InputFormat } from 'types/input-format';
import {
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
} from '@material-ui/core';
import { unitPriceFragment } from '__generated__/unitPriceFragment';
import { annualPricePerSqFt } from 'utils/annual-price-per-sq-ft';

interface IAggCompTable {
  bucketedUnitPrices: BucketUnitPricesResult;
  headerLabel: string;
}

const AggCompTable: FC<IAggCompTable> = ({
  bucketedUnitPrices,
  headerLabel,
}) => {
  const { buckets, unmatchedUnitPrices } = bucketedUnitPrices;
  return (
    <Table
      style={{ minWidth: '500px', width: 'auto', marginBottom: '20px' }}
      size="small"
    >
      <TableHead>
        <TableRow>
          <TableCell colSpan={4} style={{ textAlign: 'center' }}>
            {headerLabel}
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell>Standard Unit Sizes</TableCell>
          <TableCell align="center">Price</TableCell>
          <TableCell align="center">$/SF</TableCell>
          <TableCell align="center"># Included</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {buckets.map((bucket) => {
          const hasUnitsInBucket = bucket.unitPrices.length > 0;

          return (
            <TableRow key={`${bucket.dimension1}x${bucket.dimension2}`}>
              <TableCell>
                {bucket.dimension1} x {bucket.dimension2}
              </TableCell>
              <TableCell align="center">
                {hasUnitsInBucket
                  ? formatNumber(bucket.averagePrice, InputFormat.Money)
                  : 'N/A'}
              </TableCell>
              <TableCell align="center">
                {hasUnitsInBucket
                  ? formatNumber(bucket.annualPricePerSqFt, InputFormat.Money)
                  : 'N/A'}
              </TableCell>
              <TableCell align="center">{bucket.unitPrices.length}</TableCell>
            </TableRow>
          );
        })}
        <NonStandardSizeRows unitPrices={unmatchedUnitPrices} />
      </TableBody>
    </Table>
  );
};

const NonStandardSizeRows = ({
  unitPrices,
}: {
  unitPrices: unitPriceFragment[];
}) => {
  if (unitPrices.length === 0) {
    return null;
  }

  return (
    <>
      <TableRow>
        <TableCell style={{ fontStyle: 'italic' }} colSpan={4}>
          Non-Standard Unit Sizes
        </TableCell>
      </TableRow>
      {unitPrices.map((unitPrice) => {
        return (
          <TableRow key={unitPrice.id}>
            <TableCell>
              {unitPrice.dimension1} x {unitPrice.dimension2}
            </TableCell>
            <TableCell align="center">
              {formatNumber(unitPrice.price, InputFormat.Money)}
            </TableCell>
            <TableCell align="center">
              {formatNumber(
                annualPricePerSqFt(
                  unitPrice.dimension1,
                  unitPrice.dimension2,
                  unitPrice.price
                ),
                InputFormat.Money
              )}
            </TableCell>
            <TableCell align="center">1</TableCell>
          </TableRow>
        );
      })}
    </>
  );
};

export { AggCompTable };
