import numeral from 'numeral';
import {
  IFormValues,
  FormDefinition,
  IEditableFormField,
} from 'types/form-types';
import { isInputField } from './is-input-field';
import { isNumberInput } from './is-number-input';
import { isMultiInputField } from './is-multi-input-field';
import { isBooleanInput } from './is-boolean-input';

function parseFormValues<DataModel>(
  formValues: IFormValues<DataModel>,
  formDefinition: FormDefinition<DataModel>
): DataModel {
  let parsedFormValues = {} as DataModel;

  formDefinition.forEach((field) => {
    if (isMultiInputField(field)) {
      const parsedNestedFields = parseFormValues(formValues, field.fields);
      parsedFormValues = {
        ...parsedFormValues,
        ...parsedNestedFields,
      };
    } else if (isInputField(field)) {
      const value = formValues[field.name];
      parsedFormValues[field.name] = parseField(field, value);
    }
  });
  return parsedFormValues;
}

function parseField(field: IEditableFormField<any>, value: any) {
  if (isNumberInput(field)) {
    if (value === undefined || value === null) {
      return null;
    }
    return numeral(value).value() as any;
  } else if (isBooleanInput(field)) {
    return value ? value.toLowerCase() === 'y' : false;
  } else {
    return value;
  }
}

function parseFormValue(
  fieldName: string,
  value: any,
  formDefinition: FormDefinition<any>
) {
  const field = formDefinition.find(
    (definition) => 'name' in definition && definition.name === fieldName
  ) as IEditableFormField<any>;

  return parseField(field, value);
}

export { parseFormValues, parseFormValue };
