import gql from 'graphql-tag';

const ASSUMPTION_FRAGMENT = gql`
  fragment assumptionFragment on ProjectAssumptions {
    id
    name
    # Purchase Price
    sizeOfBuilding
    askingPrice
    maximumPrice
    offerOrPricePaid
    outparcelValue
    netRentableSqFootage
    # Cost to Remodel
    costPerSqFoot
    security
    ceilingAndSprinklers
    floor
    roof
    lights
    hvac
    parkingCustomer
    parkingRentalResurface
    parkingRentalStriping
    parkingRentalSecurity
    parkingRentalFencing
    otherRemodelCosts
    buildStorageUnitsPerSqFt
    largeFutureCapex
    developmentFeeRate
    developmentFeeMonths
    guaranteeFeeRate
    guaranteeFeeMonths
    interestRate
    interestMonths
    otherDevPhaseCosts
    #Expenses
    propertyTaxRatePerSqFt
    propertyTaxMonths
    propertyTax
    insurancePerSqFt
    insuranceMonths
    insurance
    commonAreaMaintenancePerSqFt
    commonAreaMaintenanceMonths
    commonAreaMaintenance
    utilitiesPerSqFt
    utilitiesMonths
    utilities
    otherExpenses
    operatingExpenses
    parkingLotRentalExpenses
    managementCompanyFee
    annualMinimumFee
    #Revenue
    ratePerMonth10by10
    parkingRentPerSpace
    parkingNumberOfSpaces
    assumedOccupancyParking
    parkingRevenue
    annualRetailProductsRevenuePercentage
    annualInsuranceRevenuePercentage
    upfrontSignageCash
    intendedManager
    assumedOccupancyStorage
    otherRevenue
    targetReturn
  }
`;

export { ASSUMPTION_FRAGMENT };
