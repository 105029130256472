import {
  TableRow,
  TableCell,
  IconButton,
  TextField,
  Checkbox,
  Button
} from '@material-ui/core';
import { Edit, Delete, Check, Cancel } from '@material-ui/icons';
import React, { useState, useCallback } from 'react';
import { userFragment } from '__generated__/userFragment';
import { FetchResult } from 'apollo-link';
import { UpdateUserInput, RegisterInput } from '__generated__/globalTypes';
import { ConfirmationDialog } from 'components/comfirmation-dialog';

const UserRow = ({
  user,
  updateUser,
  deleteUser
}: {
  user: userFragment;
  updateUser: (id: number, formValues: UpdateUserInput) => Promise<FetchResult>;
  deleteUser: (id: number) => Promise<any>;
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const handleDeleteConfirmation = async (confirmed: boolean) => {
    if (confirmed) {
      await deleteUser(user.id);
    }
    setConfirmDelete(false);
  };

  return (
    <>
      {isEditing ? (
        <UserEditRow
          user={user}
          onUpdate={async (formValues: UpdateUserInput) => {
            await updateUser(user.id, formValues);
            setIsEditing(false);
          }}
          onCancel={() => setIsEditing(false)}
        />
      ) : (
        <UserDisplayRow
          user={user}
          onEdit={() => setIsEditing(true)}
          onDelete={() => setConfirmDelete(true)}
        />
      )}
      <ConfirmationDialog
        open={confirmDelete}
        onClose={handleDeleteConfirmation}
        thingToDelete={user.name}
        deleteNow
      />
    </>
  );
};

const UserDisplayRow = ({
  user,
  onEdit,
  onDelete
}: {
  user: userFragment;
  onEdit: () => void;
  onDelete: () => void;
}) => {
  return (
    <TableRow>
      <TableCell>{user.name}</TableCell>
      <TableCell>{user.email}</TableCell>
      <TableCell>{user.isAdmin ? 'yes' : 'no'}</TableCell>
      <TableCell>Hidden</TableCell>
      <TableCell>
        <IconButton onClick={onEdit}>
          <Edit />
        </IconButton>
        <IconButton onClick={onDelete}>
          <Delete />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export const UserEditRow = ({
  user,
  onUpdate,
  onAddNew,
  onCancel
}: {
  user?: userFragment;
  onUpdate?: (formValues: UpdateUserInput) => void;
  onAddNew?: (formValues: RegisterInput) => Promise<any>;
  onCancel?: () => void;
}) => {
  const [formValues, setFormValues] = useState<UpdateUserInput | RegisterInput>(
    {
      email: user ? user.email : '',
      isAdmin: user ? user.isAdmin : false,
      name: user ? user.name : '',
      password: user ? undefined : ''
    }
  );
  const updateField = useCallback(
    (fieldName, newValue) => {
      setFormValues(oldFormValues => {
        return {
          ...oldFormValues,
          [fieldName]: newValue
        };
      });
    },
    [setFormValues]
  );

  const handleAddNew = useCallback(async () => {
    await onAddNew(formValues as RegisterInput);
    setFormValues({
      email: '',
      isAdmin: false,
      name: '',
      password: ''
    });
  }, [onAddNew, formValues]);

  return (
    <TableRow>
      <TableCell>
        <TextField
          value={formValues.name}
          variant="filled"
          margin="dense"
          hiddenLabel
          onChange={e => updateField('name', e.target.value)}
        />
      </TableCell>
      <TableCell>
        <TextField
          value={formValues.email}
          variant="filled"
          margin="dense"
          hiddenLabel
          onChange={e => updateField('email', e.target.value)}
        />
      </TableCell>
      <TableCell>
        <Checkbox
          checked={formValues.isAdmin}
          onChange={e => updateField('isAdmin', e.target.checked)}
        />
      </TableCell>
      <TableCell>
        <TextField
          value={formValues.password || ''}
          variant="filled"
          hiddenLabel
          margin="dense"
          placeholder={user && 'Leave blank to keep'}
          onChange={e => updateField('password', e.target.value)}
        />
      </TableCell>
      <TableCell>
        {user ? (
          <>
            <IconButton onClick={() => onUpdate(formValues)}>
              <Check />
            </IconButton>
            <IconButton onClick={onCancel}>
              <Cancel />
            </IconButton>
          </>
        ) : (
          <Button onClick={handleAddNew}>Add New</Button>
        )}
      </TableCell>
    </TableRow>
  );
};

export { UserRow };
