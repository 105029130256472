import React, {
  FC,
  useCallback,
  useState,
  useEffect,
  useRef,
  useContext,
} from 'react';
import { TextField, makeStyles } from '@material-ui/core';
import { IFormField } from 'types/form-types';
import { InputFormat } from 'types/input-format';
import { formatFieldAsFormValue } from 'utils/format-data-model-as-form-values';
import { BaseTextFieldProps } from '@material-ui/core/TextField';
import { HighlightColors, HighlightColor } from 'types/colors';
import { VersionContext } from 'containers/project-view';

const withInputStyles = makeStyles<any, { isTextInput: boolean }>({
  input: {
    textAlign: (props) => {
      return props.isTextInput ? 'left' : 'right';
    },
  },
});

const withHighlightStyles = makeStyles<any, { highlightColor: HighlightColor }>(
  {
    root: {
      backgroundColor: (props) =>
        props.highlightColor && props.highlightColor.base,
      '&:hover': {
        backgroundColor: (props) =>
          props.highlightColor && props.highlightColor.hover,
      },
      '&.Mui-disabled': {
        backgroundColor: (props) =>
          props.highlightColor && props.highlightColor.base,
      },
      '&.Mui-focused': {
        backgroundColor: (props) =>
          props.highlightColor && props.highlightColor.base,
      },
    },
  }
);

type IInputCellProps = BaseTextFieldProps & {
  formField: IFormField<any>;
  value: string | number;
  onChange?: (value: string) => void;
  inputRef?: React.Ref<HTMLInputElement>;
  autoFocus?: boolean;
  onBlur?: () => void;
  color?: string;
};

let GUID = 1;

const InputCell: FC<IInputCellProps> = ({
  formField,
  onChange,
  value,
  inputRef,
  autoFocus,
  onBlur,
  color,
  ...textFieldProps
}) => {
  const { format, fieldLabel } = formField;
  const inputStyles = withInputStyles({
    isTextInput: format === InputFormat.String,
  });
  const highlightStyles = withHighlightStyles({
    highlightColor: HighlightColors[color],
  });

  let inputClasses = inputStyles;
  // If these are applied all the time, they don't clear out when removing color :/
  if (color) {
    inputClasses = {
      ...inputClasses,
      ...highlightStyles,
    };
  }
  const idRef = useRef(`textField-${GUID++}`);
  const id = idRef.current;

  const dirtyRef = useRef(false);

  const initialValue = useRef('');

  const { viewingCurrentVersion } = useContext(VersionContext);

  const [internalValue, setInternalValue] = useState('');
  useEffect(() => {
    setInternalValue(`${formatFieldAsFormValue(formField, value)}`);
  }, [value, formField]);

  const handleChange = useCallback((event) => {
    setInternalValue(event.target.value);
    dirtyRef.current = true;
  }, []);

  const handleBlur = useCallback(
    (event) => {
      if (onBlur) {
        onBlur();
      }

      if (dirtyRef.current === false) {
        return;
      }

      dirtyRef.current = false;

      const newValue = event.target.value;
      if (onChange) {
        const formattedValue = formatFieldAsFormValue(formField, newValue);
        onChange(formattedValue);
        setInternalValue(`${formattedValue}`);
      }
    },
    [setInternalValue, onChange, formField, onBlur]
  );

  const handleFocus = useCallback(() => {
    initialValue.current = internalValue;
  }, [internalValue]);

  const handleKeyPress = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        const thisInput = document.getElementById(id);
        const thisCell = thisInput.closest('td');

        const thisCellsInputs = thisCell.querySelectorAll('input');
        for (let i = 0; i < thisCellsInputs.length - 1; i++) {
          if (thisCellsInputs[i].id === id) {
            thisCellsInputs[i + 1].focus();
            return;
          }
        }

        const cellIndex = thisCell.cellIndex;
        let rowIndex = thisInput.closest('tr').rowIndex + 1;

        const table = thisInput.closest('table');
        const tableRows = table.querySelectorAll('tr');

        while (rowIndex < tableRows.length) {
          const tableRow = tableRows[rowIndex];
          const cellsInRow = tableRow.querySelectorAll('td');
          if (cellsInRow.length > cellIndex) {
            const input = cellsInRow[cellIndex].querySelector('input');
            if (input) {
              input.focus();
              input.click();
              input.select();
              return;
            }
          }

          rowIndex++;
        }
        thisInput.blur();
      }
    },
    [id]
  );

  let styles: React.CSSProperties = {};
  if (formField.inputWidth) {
    styles.width = formField.inputWidth;
  }

  return (
    <TextField
      {...textFieldProps}
      id={id}
      autoFocus={autoFocus}
      onKeyPress={handleKeyPress}
      inputRef={inputRef}
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      value={internalValue || ''}
      label={fieldLabel}
      variant="filled"
      margin="dense"
      style={styles}
      fullWidth={false}
      InputProps={{
        classes: inputClasses,
      }}
      hiddenLabel={!fieldLabel}
      autoComplete={'off'}
      disabled={!viewingCurrentVersion}
    />
  );
};

export { InputCell };
