import { FormDefinition, HeaderType } from 'types/form-types';
import { InputFormat } from 'types/input-format';
import { assumptionFragment } from '__generated__/assumptionFragment';

function totalPerMonth(formValues: assumptionFragment, newValue: number) {
  return formValues.sizeOfBuilding * newValue;
}

function perSquareFoot(formValues: assumptionFragment, newValue: number) {
  return newValue / formValues.sizeOfBuilding;
}

function otherDevPhaseCosts(formValues: assumptionFragment) {
  const {
    propertyTax,
    insurance,
    commonAreaMaintenance,
    utilities,
    otherExpenses,
  } = formValues;
  return (
    propertyTax + insurance + commonAreaMaintenance + utilities + otherExpenses
  );
}

export const calculateOtherDevPhaseCosts = {
  key: 'otherDevPhaseCosts' as keyof assumptionFragment,
  calculate: (formValues: assumptionFragment, _: any) =>
    otherDevPhaseCosts(formValues),
};

const ExpenseFields: FormDefinition<assumptionFragment> = [
  {
    text: 'Expense Assumptions',
    type: HeaderType.Section,
  },
  {
    label: 'Property Taxes',
    fields: [
      {
        name: 'propertyTaxRatePerSqFt',
        fieldLabel: 'Rate/SF',
        format: InputFormat.Money,
        recalculate: [
          {
            key: 'propertyTax',
            calculate: totalPerMonth,
          },
          calculateOtherDevPhaseCosts,
        ],
      },
      {
        name: 'propertyTax',
        format: InputFormat.Money,
        fieldLabel: 'Total',
        recalculate: [
          {
            key: 'propertyTaxRatePerSqFt',
            calculate: perSquareFoot,
          },
          calculateOtherDevPhaseCosts,
        ],
      },
    ],
  },
  {
    label: 'Insurance',
    fields: [
      {
        name: 'insurancePerSqFt',
        fieldLabel: 'Rate/SF',
        format: InputFormat.Money,
        recalculate: [
          {
            key: 'insurance',
            calculate: totalPerMonth,
          },
          calculateOtherDevPhaseCosts,
        ],
      },
      {
        name: 'insurance',
        format: InputFormat.Money,
        recalculate: [
          {
            key: 'insurancePerSqFt',
            calculate: perSquareFoot,
          },
          calculateOtherDevPhaseCosts,
        ],
      },
    ],
  },
  {
    label: 'Common Area Maintenance (CAM)',
    fields: [
      {
        name: 'commonAreaMaintenancePerSqFt',
        fieldLabel: 'Rate/SF',
        format: InputFormat.Money,
        recalculate: [
          {
            key: 'commonAreaMaintenance',
            calculate: totalPerMonth,
          },
          calculateOtherDevPhaseCosts,
        ],
      },
      {
        name: 'commonAreaMaintenance',
        format: InputFormat.Money,
        recalculate: [
          {
            key: 'commonAreaMaintenancePerSqFt',
            calculate: perSquareFoot,
          },
          calculateOtherDevPhaseCosts,
        ],
      },
    ],
  },
  {
    label: 'Utilities',
    fields: [
      {
        name: 'utilitiesPerSqFt',
        fieldLabel: 'Rate/SF',
        format: InputFormat.Money,
        recalculate: [
          {
            key: 'utilities',
            calculate: totalPerMonth,
          },
          calculateOtherDevPhaseCosts,
        ],
      },
      {
        name: 'utilities',
        format: InputFormat.Money,
        recalculate: [
          {
            key: 'utilitiesPerSqFt',
            calculate: perSquareFoot,
          },
          calculateOtherDevPhaseCosts,
        ],
      },
    ],
  },
  {
    name: 'otherExpenses',
    label: 'Other',
    format: InputFormat.Money,
    recalculate: [calculateOtherDevPhaseCosts],
  },
  {
    name: 'operatingExpenses',
    label: 'Operating Expenses (Wages, R&M, Mktg)',
    format: InputFormat.Money,
    defaultValue: '220,000',
  },
  {
    name: 'parkingLotRentalExpenses',
    label: 'Parkling Lot Rental Expenses',
    format: InputFormat.Money,
  },
  {
    name: 'totalExpensesBeforeManagementFee',
    label: 'Total Expenses Before Management Fee',
    format: InputFormat.Money,
    calculate: totalExpensesBeforeManagmentFee,
  },
  {
    name: 'managementCompanyFee',
    label: 'Management Company Fee (% of Revenue)',
    format: InputFormat.Percentage,
    defaultValue: '7%',
  },
  {
    name: 'annualMinimumFee',
    label: 'Annual Minimum Fee',
    format: InputFormat.Money,
    defaultValue: '42,000',
  },
];

export function totalExpensesBeforeManagmentFee(
  parsedFormValues: assumptionFragment
) {
  const {
    propertyTax,
    insurance,
    utilities,
    otherExpenses,
    operatingExpenses,
    parkingLotRentalExpenses,
    commonAreaMaintenance,
  } = parsedFormValues;

  return (
    propertyTax +
    insurance +
    commonAreaMaintenance +
    utilities +
    otherExpenses +
    operatingExpenses +
    parkingLotRentalExpenses
  );
}

export { ExpenseFields };
