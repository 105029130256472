const highlightBaseOpacity = 0.5;
const highlightHoverOpacity = 0.6;

type ColorMap = { [colorName: string]: string };

export const Colors: ColorMap = {
  green: '0,177,106',
  yellow: '245,229,27',
  red: '246,71,71'
  /*blue: '137,196,244'*/
};

export type HighlightColor = { base: string; hover: string };
export const HighlightColors = Object.entries(Colors).reduce(
  (agg, [name, rgb]) => {
    agg[name] = {
      base: `rgba(${rgb}, ${highlightBaseOpacity})`,
      hover: `rgba(${rgb}, ${highlightHoverOpacity})`
    };
    return agg;
  },
  {} as { [colorName: string]: HighlightColor }
);
