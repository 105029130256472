import { unitPriceFragment } from '__generated__/unitPriceFragment';
import React, { FC, useMemo, useState, useEffect } from 'react';
import { formatDataModelAsFormValues } from 'utils/format-data-model-as-form-values';
import { parseFormValues } from 'utils/parse-form-values';
import { Button, TableCell, TableRow } from '@material-ui/core';
import { UnitPriceFields } from 'form-definitions/unit-price-fields';
import { InputCell } from 'components/input-cell';

interface IUnitPriceFormProps {
  onSubmit: (unitPrice: unitPriceFragment) => void;
  unitPrice?: unitPriceFragment;
  focusOnRender?: boolean;
}
const defaultFormData = UnitPriceFields.reduce((acc, field) => {
  acc[field.name] = '';
  return acc;
}, {} as any);

const UnitPriceForm: FC<IUnitPriceFormProps> = ({
  onSubmit,
  unitPrice,
  focusOnRender,
}) => {
  const unitPriceFormData = useMemo(() => {
    if (!unitPrice) return null;
    return formatDataModelAsFormValues(UnitPriceFields, unitPrice);
  }, [unitPrice]);
  const [formData, setFormData] = useState(
    unitPriceFormData || defaultFormData
  );
  const firstInputRef = React.createRef<HTMLInputElement>();

  useEffect(() => {
    if (firstInputRef.current && focusOnRender) {
      firstInputRef.current.focus();
    }
  }, []);

  const handleSubmit = () => {
    const parsedUnitPrice = parseFormValues(formData, UnitPriceFields);
    parsedUnitPrice.id = (unitPrice && unitPrice.id) || null;

    onSubmit(parsedUnitPrice);
    setFormData(defaultFormData);
    if (firstInputRef.current) {
      firstInputRef.current.focus();
    }
  };

  const cancelEdit = () => {
    onSubmit(unitPrice);
  };

  return (
    <TableRow>
      {UnitPriceFields.map((field, idx) => {
        return (
          <TableCell key={field.name}>
            <InputCell
              inputRef={idx === 0 ? firstInputRef : undefined}
              formField={field}
              key={field.name}
              value={formData[field.name]}
              onChange={(value) =>
                setFormData({ ...formData, [field.name]: value })
              }
            />
          </TableCell>
        );
      })}
      <TableCell>
        <Button onFocus={handleSubmit}>{unitPrice ? 'Save' : 'Add'}</Button>
        {unitPrice && <Button onClick={cancelEdit}>Cancel</Button>}
      </TableCell>
    </TableRow>
  );
};

export { UnitPriceForm };
