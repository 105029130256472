import gql from 'graphql-tag';
import { UNIT_PRICE_FRAGMENT } from './unit-price-fragment';

const COMPETITOR_FRAGMENT = gql`
  fragment competitorFragment on Competitor {
    id
    name
    address
    telephone
    listingWebsite
    distanceFromProposedSite
    climateControlledPercentage
    totalSquareFootage
    unitsVacant
    unitPrices {
      ...unitPriceFragment
    }
  }
  ${UNIT_PRICE_FRAGMENT}
`;

export { COMPETITOR_FRAGMENT };
