import React, { FC } from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import { ProjectViewContainer } from 'containers/project-view';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ProjectListContainer } from 'containers/project-list';
import { CssBaseline } from '@material-ui/core';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import ApolloClient from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { singleProject } from 'routes';
import { Authenticated } from 'Authenticated';
import { AdminContainer } from 'containers/admin';
import { ExcelUploader } from 'containers/excel-uploader';

const uri =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:4000/graphql'
    : '/graphql';

const cleanTypeName = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    const omitTypename = (key: string, value: any) => {
      if (key === '__typename') {
        return undefined;
      }
      if (key === 'id' && value < 0) {
        return +value;
      }
      return value;
    };

    operation.variables = JSON.parse(
      JSON.stringify(operation.variables),
      omitTypename
    );
  }
  return forward(operation).map((data) => {
    return data;
  });
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const client = new ApolloClient({
  link: ApolloLink.from([
    authLink,
    cleanTypeName,
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(
          ({ message, locations, path, extensions: { code } }) => {
            if (code === 'UNAUTHENTICATED') {
              window.localStorage.removeItem('token');
              if (window.location.pathname !== '/login') {
                window.location.href = '/login';
              }
            } else {
              console.log(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
              );
              alert(`Error, send this to Ford "[GraphQL error]: ${message}"`);
            }
          }
        );
      }
      if (networkError) console.log(`[Network error]: ${networkError}`);
    }),
    new HttpLink({
      uri,
    }),
  ]),
  cache: new InMemoryCache({
    addTypename: true,
  }),
});

const App: FC = () => {
  return (
    <ApolloProvider client={client}>
      <CssBaseline />
      <Authenticated>
        <Router>
          <Switch>
            <Route exact path="/excel-uploader" component={ExcelUploader} />
            <Route path={singleProject} component={ProjectViewContainer} />
            <Route path={'/admin'} component={AdminContainer} />
            <Route path="/" component={ProjectListContainer} />
          </Switch>
        </Router>
      </Authenticated>
    </ApolloProvider>
  );
};

export default App;
