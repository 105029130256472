enum InputFormat {
  Money = '$0,0.00',
  MoneyNoDecimal = '$0,0',
  Number = '0,0.[00]',
  Percentage = '0.[00]%',
  String = 'string',
  Boolean = 'boolean'
}

export { InputFormat };
