import React, { FC, useEffect, useState, useMemo, useCallback } from 'react';
import gql from 'graphql-tag';
import { PROJECT_FRAGMENT } from 'apollo/fragments/project-fragment';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { ProjectView } from 'components/project-view';
import { RouteComponentProps } from 'react-router';
import { getProject, getProjectVariables } from '__generated__/getProject';
import moment, { Moment } from 'moment';
import {
  getProjectMasterRecord,
  getProjectMasterRecordVariables,
} from '__generated__/getProjectMasterRecord';
import { saveProject, saveProjectVariables } from '__generated__/saveProject';

const GET_PROJECT_MASTER_RECORD = gql`
  query getProjectMasterRecord($id: Int!) {
    projectMasterRecord(id: $id) {
      id
      markedDeadAt
      currentProjectVersion {
        id
      }
      versions {
        id
        createdAt
        createdByUser {
          name
        }
        version
        approvedAt
      }
    }
  }
`;

const GET_PROJECT_VERSION = gql`
  query getProject($id: Int!) {
    getProject(id: $id) {
      ...projectFragment
    }
  }
  ${PROJECT_FRAGMENT}
`;

export const SAVE_PROJECT = gql`
  mutation saveProject($projectInput: ProjectInput) {
    saveProject(input: $projectInput) {
      ...projectFragment
    }
  }
  ${PROJECT_FRAGMENT}
`;

interface IProjectViewRouteParams {
  id: string;
}

interface IProjectViewContainer
  extends RouteComponentProps<IProjectViewRouteParams> {}

export const VersionContext = React.createContext({
  viewingCurrentVersion: true,
});

const ProjectViewContainer: FC<IProjectViewContainer> = ({
  match,
  history,
}) => {
  const masterRecordResult = useQuery<
    getProjectMasterRecord,
    getProjectMasterRecordVariables
  >(GET_PROJECT_MASTER_RECORD, {
    variables: {
      id: +match.params.id,
    },
    fetchPolicy: 'network-only',
  });

  const [projectVersionId, setProjectVersionId] = useState<number>();

  useEffect(() => {
    if (
      !masterRecordResult.loading &&
      masterRecordResult.data &&
      !projectVersionId
    ) {
      setProjectVersionId(
        masterRecordResult.data.projectMasterRecord.currentProjectVersion.id
      );
    }
  }, [masterRecordResult, projectVersionId]);

  const currentVersionResult = useQuery<getProject, getProjectVariables>(
    GET_PROJECT_VERSION,
    {
      variables: {
        id: projectVersionId,
      },
      skip: !projectVersionId,
    }
  );

  const [saveProject, saveProjectResult] = useMutation<
    saveProject,
    saveProjectVariables
  >(SAVE_PROJECT, {
    awaitRefetchQueries: true,
    refetchQueries: ['getProjectMasterRecord'],
  });

  const [lastSuccessfulSave, setLastSuccessfulSave] = useState<Moment>();

  useEffect(() => {
    if (
      saveProjectResult.called &&
      saveProjectResult.data &&
      !saveProjectResult.error &&
      !saveProjectResult.loading
    ) {
      setLastSuccessfulSave(moment());
      setProjectVersionId(saveProjectResult.data.saveProject.id);
    }
  }, [saveProjectResult.loading]);

  const handleVersionChange = useCallback((newVersionId: number) => {
    setProjectVersionId(newVersionId);
  }, []);

  if (masterRecordResult.error) {
    console.log(masterRecordResult.error);
    return <div>error</div>;
  }

  return !currentVersionResult.data || !masterRecordResult.data ? (
    <div>loading</div>
  ) : (
    <VersionContext.Provider
      value={{
        viewingCurrentVersion:
          currentVersionResult.data.getProject.id ===
          masterRecordResult.data.projectMasterRecord.currentProjectVersion.id,
      }}
    >
      <ProjectView
        key={currentVersionResult.data.getProject.id}
        project={currentVersionResult.data.getProject}
        versions={masterRecordResult.data.projectMasterRecord.versions}
        saveProject={saveProject}
        saving={saveProjectResult.loading || currentVersionResult.loading}
        lastSuccessfulSave={lastSuccessfulSave}
        saveError={saveProjectResult.error && saveProjectResult.error.message}
        onVersionChange={handleVersionChange}
        projectMasterRecord={masterRecordResult.data.projectMasterRecord}
      />
    </VersionContext.Provider>
  );
};

export { ProjectViewContainer };
