import {
  ListItem,
  IconButton,
  Card,
  CardContent,
  Typography,
  List,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import { ProjectTab } from 'components/project-bar';
import React, { useContext } from 'react';

import { loadProjectList_projectMasterRecordCollection } from '__generated__/loadProjectList';
import { UserContext } from 'Authenticated';

function InternalCardList({
  projects,
  onDelete,
}: {
  projects: loadProjectList_projectMasterRecordCollection[];
  onDelete: (project: loadProjectList_projectMasterRecordCollection) => void;
}) {
  const { currentUser } = useContext(UserContext);

  return (
    <List>
      {projects.map((project) => {
        return (
          <ListItem key={project.id}>
            {currentUser.isAdmin && (
              <IconButton onClick={() => onDelete(project)}>
                <DeleteIcon />
              </IconButton>
            )}
            <Link
              to={`project/${project.id}/${ProjectTab.Controls}`}
              style={{ textDecoration: 'none' }}
            >
              <Card style={{ width: '400px' }}>
                <CardContent>
                  <Typography>{project.currentProjectVersion.name}</Typography>
                  <Typography color="textSecondary" variant="body2">
                    {new Date(
                      +project.currentProjectVersion.createdAt
                    ).toLocaleString()}
                  </Typography>
                </CardContent>
              </Card>
            </Link>
          </ListItem>
        );
      })}
    </List>
  );
}

const CardList = React.memo(InternalCardList);
export { CardList };
