import {
  FormDefinition,
  IFormValues,
  IDataModel,
  IEditableFormField,
} from 'types/form-types';
import { formatNumber } from 'utils/format-number';
import { isNumberInput } from 'utils/is-number-input';
import { isInputField } from 'utils/is-input-field';
import { isMultiInputField } from './is-multi-input-field';
import { isBooleanInput } from './is-boolean-input';

function formatDataModelAsFormValues<DataModel extends IDataModel>(
  fieldDefinitions: FormDefinition<DataModel>,
  dataModel: DataModel,
  formValues: IFormValues<DataModel> = {} as IFormValues<DataModel>
): IFormValues<DataModel> {
  fieldDefinitions.forEach((field) => {
    if (isMultiInputField(field)) {
      formatDataModelAsFormValues(field.fields, dataModel, formValues);
    } else {
      if (isInputField(field)) {
        formValues[field.name] = formatFieldAsFormValue(
          field,
          dataModel[field.name],
          false
        );
      }
    }
  });

  Object.keys(dataModel).forEach((key: keyof DataModel) => {
    if (!(key in formValues)) {
      formValues[key] = dataModel[key];
    }
  });

  return formValues;
}

const formatFieldAsFormValue = <DataModel>(
  field: IEditableFormField<DataModel>,
  currentValue: any,
  isUserInput = true
) => {
  if (isNumberInput(field) && currentValue !== undefined) {
    return formatNumber(
      currentValue || field.defaultValue || '',
      field.format,
      isUserInput
    );
  } else if (isBooleanInput(field)) {
    const isTruthy =
      currentValue &&
      (currentValue === true ||
        currentValue.toLowerCase() === 'y' ||
        currentValue === '1');
    return isTruthy ? 'Y' : 'N';
  } else {
    return currentValue || field.defaultValue || '';
  }
};

export { formatDataModelAsFormValues, formatFieldAsFormValue };
