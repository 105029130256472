import React, { FC, useState, useCallback } from 'react';
import { InputCell } from 'components/input-cell';
import { InputFormat } from 'types/input-format';
import { IconButton } from '@material-ui/core';
import { ConfirmationDialog } from 'components/comfirmation-dialog';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CloneIcon from '@material-ui/icons/FileCopy';
import { FocusableTableCell } from 'components/focusable-table-cell';

interface IAssumptionNameProps {
  value: string;
  onChange: (newValue: string) => void;
  onClone: () => void;
  onDelete: () => void;
}

const AssumptionName: FC<IAssumptionNameProps> = ({
  value,
  onChange,
  onClone,
  onDelete
}) => {
  const [editing, setEditing] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const handleDeleteConfirmation = useCallback(
    (confirmed: boolean) => {
      if (confirmed) {
        onDelete();
      }
      setConfirmDelete(false);
    },
    [onDelete]
  );

  return (
    <>
      <FocusableTableCell
        align="center"
        variant="head"
        style={{ cursor: 'pointer' }}
      >
        {({ focused }) => {
          return (
            <>
              {editing ? (
                <InputCell
                  autoFocus
                  formField={{
                    name: 'name',
                    format: InputFormat.String
                  }}
                  value={value}
                  onChange={onChange}
                  onBlur={() => setEditing(false)}
                />
              ) : (
                <div>{value}</div>
              )}
              {focused && (
                <>
                  <IconButton onClick={() => setEditing(true)} size="small">
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={onClone} size="small">
                    <CloneIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => setConfirmDelete(true)}
                    size="small"
                  >
                    <DeleteIcon />
                  </IconButton>
                </>
              )}
            </>
          );
        }}
      </FocusableTableCell>

      <ConfirmationDialog
        open={confirmDelete}
        onClose={handleDeleteConfirmation}
        thingToDelete={value}
      />
    </>
  );
};
export { AssumptionName };
