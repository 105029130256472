import { FormDefinition, HeaderType } from 'types/form-types';
import { InputFormat } from 'types/input-format';
import { assumptionFragment } from '__generated__/assumptionFragment';
import { calculateOtherDevPhaseCosts } from './expense-fields';

const PurchasePriceAssumptionFields: FormDefinition<assumptionFragment> = [
  {
    text: 'Purchase Price Assumptions',
    type: HeaderType.Section,
  },
  {
    name: 'sizeOfBuilding',
    label: 'Size of Building (Square Feet)',
    format: InputFormat.Number,
    recalculate: [
      {
        key: 'propertyTax',
        calculate: (formValues, newValue) =>
          newValue * formValues.propertyTaxRatePerSqFt,
      },
      {
        key: 'insurance',
        calculate: (formValues, newValue) =>
          newValue * formValues.insurancePerSqFt,
      },
      {
        key: 'commonAreaMaintenance',
        calculate: (formValues, newValue) =>
          newValue * formValues.commonAreaMaintenancePerSqFt,
      },
      {
        key: 'utilities',
        calculate: (formValues, newValue) =>
          newValue * formValues.utilitiesPerSqFt,
      },
      calculateOtherDevPhaseCosts,
    ],
  },
  {
    name: 'askingPrice',
    label: 'Asking Price',
    format: InputFormat.Money,
  },
  {
    name: 'askingPricePerSqFoot',
    label: 'Price per Square Foot',
    nested: true,
    format: InputFormat.Money,
    calculate: ({ askingPrice, sizeOfBuilding }) => {
      return askingPrice / sizeOfBuilding;
    },
  },
  {
    name: 'maximumPrice',
    label: 'Maximum Price',
    format: InputFormat.Money,
  },
  {
    name: 'maximumPricePerSqFoot',
    label: 'Price per Square Foot',
    nested: true,
    format: InputFormat.Money,
    calculate: ({ maximumPrice, sizeOfBuilding }) => {
      return maximumPrice / sizeOfBuilding;
    },
  },
  {
    name: 'offerOrPricePaid',
    label: 'Actual Offer / Price Paid',
    format: InputFormat.Money,
  },
  {
    name: 'actualPricePerSqFoot',
    label: 'Price per Square Foot',
    nested: true,
    format: InputFormat.Money,
    calculate: ({ offerOrPricePaid, sizeOfBuilding }) => {
      return offerOrPricePaid / sizeOfBuilding;
    },
  },
  {
    name: 'outparcelValue',
    label: 'Outparcel Value',
    format: InputFormat.Money,
  },
  {
    name: 'offerOrPricePaidNetOutparcel',
    label: 'Actual Offer / Price Paid net of Ourparcel Value',
    format: InputFormat.Money,
    calculate: offerOrPricePaidNetOutparcel,
  },
  {
    name: 'pricePaidNetOutparcelPerSqFt',
    label: 'Price per Square Foot',
    nested: true,
    format: InputFormat.Money,
    calculate: (values) => {
      return offerOrPricePaidNetOutparcel(values) / values.sizeOfBuilding;
    },
  },
  {
    name: 'netRentableSqFootage',
    label: 'Net Rentable Square Footage',
    format: InputFormat.Percentage,
  },
];

export function offerOrPricePaidNetOutparcel(
  parsedFormValues: assumptionFragment
) {
  const { offerOrPricePaid, outparcelValue } = parsedFormValues;

  return offerOrPricePaid - outparcelValue;
}

export { PurchasePriceAssumptionFields };
